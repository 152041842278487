import React, { useContext } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import DisplayItem from "../MyHealthRecord-Layout/Common/DisplayItem";
import DisplayTitleItem from "../MyHealthRecord-Layout/Common/DisplayTitleItem";
import { TX, NM } from "../../define.constants";
import { AppContext } from "../../contexts/app.context";
import { getFormattedDate } from "../../utils/helpers";
import { useCTContactInforAccess } from "../../hooks/useCTContactInforAccess";

const CareCoordinationRequestHistory = (props) => {
  const { fields, data } = props;
  const { MemberStateName } = useContext(AppContext);
  return (
    <>
      {MemberStateName != TX && MemberStateName != NM && (
        <div className="row mb20">
          <div className="col-md-12 ohio-subheading">
            <Text field={props.fields.TitleHistory} />
          </div>
        </div>
      )}
      {data?.length == 0 ? (
        <p className="no-records">
          <Text field={props.fields.lblNoResults} />
        </p>
      ) : (
        <>
          <br></br>
          <br></br>
          {data?.map((item, index) => (
            <div className="rquestHistory" key={index}>
              <div className="blockSec2 mb20" id="recordsToPrint">
                {/* <li className="list-group-item "> */}
                <div className="row">
                  <DisplayItem
                    nameField={
                      MemberStateName == TX || MemberStateName == NM
                        ? fields.lblSrNo
                        : fields.lblAssigneDate
                    }
                    valueField={
                      MemberStateName == TX || MemberStateName == NM
                        ? item.SerialNo
                        : item.Datestr
                    }
                    outClass={"col-xxl-2 col-xl-2 col-md-6 col-sm-12"}
                    titleClass={"list-desc-title"}
                    detailsClass={"list-desc-details"}
                  />
                  <DisplayItem
                    nameField={
                      MemberStateName == TX || MemberStateName == NM
                        ? fields.lblDate
                        : fields.lblRequestingEntity
                    }
                    valueField={
                      MemberStateName == TX || MemberStateName == NM
                        ? item.SubmitDateDisplay
                        : item.RequestingEntity
                    }
                    outClass={"col-xxl-3 col-xl-3 col-md-6 col-sm-12"}
                    titleClass={"list-desc-title"}
                    detailsClass={"list-desc-details"}
                  />
                  <DisplayItem
                    nameField={fields.lblReasonForRequesting}
                    valueField={
                      MemberStateName == TX || MemberStateName == NM
                        ? item.ReasonOfRequest
                        : item.ReasonForRequesting
                    }
                    outClass={"col-xxl-4 col-xl-4 col-md-6 col-sm-12"}
                    titleClass={"list-desc-title"}
                    detailsClass={"list-desc-details"}
                  />
                  <DisplayItem
                    nameField={fields.lblRequestStatus}
                    valueField={
                      MemberStateName == TX || MemberStateName == NM
                        ? item.RequestStatus
                        : item.RequestStatus
                    }
                    outClass={"col-xxl-3 col-xl-3 col-md-6 col-sm-12"}
                    titleClass={"list-desc-title"}
                    detailsClass={"list-desc-details"}
                  />
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default CareCoordinationRequestHistory;
