import React, { useContext } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "../../../../../contexts/app.context";
import { IMAGE_PATH } from "../../../../../define.constants";

const ViewIDCardMedicareNM = ({
  fields,
  result,
  details,
  idCard,
  drugInfo,
}) => {
  let { MemberStateName, planName, memberLOB } = useContext(AppContext);
  let portalDeductiblesDetail =
    result?.PortalDeductiblesMappingDetails &&
    result?.PortalDeductiblesMappingDetails.length > 0
      ? result?.PortalDeductiblesMappingDetails[0]
      : null;
  const formatToday = () => {
    let date = new Date();
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  // Pulling NAL English number
  const contactinfo = () => {
    return fields.lblIDBackNALData;
  };

  return (
    <>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 id-card-new">
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/Medicare/Molina_IDCard_CompleteCare2023.png)`,
          }}
          className="background-idcard background-id-row"
        >
          <br></br>
          <table
            className="medicare-table"
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
          >
            <tbody>
              <tr>
                <td>
                  <Text field={fields.lblMember} />
                  &nbsp; {idCard?.Member_Name}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblmemberno} />
                  </b>
                  &nbsp; {idCard?.Member_Number}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblMedicaidNumber} />
                  </b>
                  &nbsp; {idCard?.MedicaidId}
                </td>
              </tr>
              <tr>
                <td className="height-adjustment-pcp"></td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblPcp} />
                  </b>
                  &nbsp;
                  {idCard?.PCPName_Current}
                </td>

                <td>
                  &nbsp;&nbsp;
                  <Text field={fields.lblBin} />
                  &nbsp;
                  {result?.PortalDeductiblesMappingDetails[0]?.RxBIN}
                </td>
              </tr>
              <tr>
                <td style={{ height: "3px" }}></td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblPrimaryCareNumber} />
                  </b>
                  &nbsp; {idCard?.PCPPhone_Current}
                </td>

                <td>
                  &nbsp;&nbsp;
                  <Text field={fields.lblPCN} />
                  &nbsp; {result?.PortalDeductiblesMappingDetails[0]?.RxPCN}
                </td>
              </tr>
              <tr>
                <td style={{ height: "3px" }}></td>
              </tr>
              <tr>
                <td></td>
                <td>
                  &nbsp;&nbsp;
                  <Text field={fields.lblGroup} />
                  &nbsp;{result?.PortalDeductiblesMappingDetails[0]?.RxGroup}
                </td>
              </tr>
              <tr>
                <td style={{ height: "3px" }}></td>
              </tr>
              <tr>
                <td></td>
                <td>
                  &nbsp;&nbsp;
                  <Text field={fields.lblRxid} />
                  &nbsp;
                  {idCard?.OriginalMemberProfile?.Number}
                </td>
              </tr>
              <tr className="medicare-id-card-spacer">
                <td style={{ height: "49px" }}></td>
              </tr>
              <tr>
                <td></td>
                <td style={{ fontSize: "14px" }}>{}</td>
              </tr>
              <tr>
                <td style={{ height: "12px" }}></td>
              </tr>
              <tr>
                <td></td>
                <td>
                  &nbsp;&nbsp;
                  {result?.PortalDeductiblesMappingDetails[0]?.ContractPlanId}
                </td>
              </tr>
              <tr>
                <td>
                  <Text field={fields.lblIssueDate} />
                  &nbsp;{formatToday()}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 id-card-new">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/WI/Medicare/Medicare_ID_Card_Molina_BLANK_220914_r1_FNL2.png)`,
          }}
          className="member-id-card-back"
          id="backMedicare"
        >
          <table
            width="96%"
            border="0"
            cellPadding="2"
            cellSpacing="0"
            id="memberCurrentID"
            //style={{ fontSize: "12px" }}
          >
            <tbody>
              <tr>
                <td>
                  <div className="TXbackwidth">
                    <ul className="list-unstyled TXmrgn">
                      <li>
                        <b>
                          <Text field={fields.lblIDBackHeading} />
                        </b>
                      </li>
                      <li>
                        <b>
                          <Text field={fields.lblIDBackServices} />
                        </b>
                        &nbsp;
                        <Text field={fields?.lblMemberServicesMedcareValueNM} />
                      </li>
                      <li>
                        <b>
                          <Text field={fields.lblIDBackNAL} />
                        </b>
                        {contactinfo().value}
                      </li>
                      <li>
                        <b>
                          <Text field={fields.lblIDBackTransportation} />
                        </b>
                        &nbsp;
                        <Text field={fields.lblIDBackTransportationData} />
                      </li>
                      <br></br>
                      <li>
                        <b>
                          <Text field={fields.lblIDBackProvider} />
                        </b>
                        &nbsp;
                        <Text field={fields.lblIDBackProviderData} />
                      </li>
                      <br></br>
                      <li>
                        <b>
                          <Text field={fields.lblbackSendClaims} />
                        </b>
                      </li>
                      <li>
                        <b>
                          <Text field={fields.lblbackMedicalHospital} />
                        </b>
                        &nbsp;
                        <Text field={fields.lblbackmedicalData} />
                      </li>
                      <li>
                        <b>
                          <Text field={fields.lblbackPharmacy} />
                        </b>
                        &nbsp;
                        <Text field={fields.lblbackPharmacyData} />
                      </li>
                      <li>
                        <b>
                          <Text field={fields.lblbackGeneralMailingAddress} />
                        </b>
                        &nbsp;
                        <Text field={fields.lblbackGeneralMailingAddressData} />
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ViewIDCardMedicareNM;
