import React, { useState, useEffect, useContext } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { useViewLabResultsAPI } from "../../hooks/useViewLabResultsAPI";
import { useGetMemberLabResultsAPI } from "../../hooks/useGetMemberLabResultsAPI";
import { Formik, Form } from "formik";
import LabResultsRecords from "./LabResultsRecords";
import { getFormattedDate } from "../../utils/helpers";
import LabResultsRecordsMobile from "./LabResultsRecordsMobile";
import { printReportDate, formSchema } from "../Common-Health-Records/Helper";
import ServiceDescription from "../MyHealthRecord-Layout/Common/ServiceDescription";
import DisplaySearch from "../MyHealthRecord-Layout/Common/DisplaySearch";
import { SpinnerContext } from "../../contexts/spinner.context";
import { MY_HEALTH_RECORD_GET_LAB_RESULTS } from "../../define.widgets";
import {
  GLOBAL_VARIABLE,
  TX_MEDICAID_STAR_PLUS,
  TX,
  DATE_MM_DD_YYYY,
} from "../../define.constants";
import { AppContext } from "./../../contexts/app.context";
import MemberLabResults from "./MemberLabResults";
import MemberLabResultsMobile from "./MemberLabResultsMobile";

const MyHealthRecordLabResults = (props) => {
  let { isCABrightcareMember } = useContext(AppContext);
  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");

  /** Search useState Variables */
  const [searchTerm, setSearchTerm] = useState(null);
  const [userAction, setUserAction] = useState(null);
  const { innerSpinnerHTML } = useContext(SpinnerContext);
  /**Filtering useState Variables */
  const [filteredRecords, setFilteredRecords] = useState([]);

  const {
    viewLabResultsList,
    startDateDisclaimer,
    labResultsDisclaimer,
    disclaimer,
    islabResultsloaded,
  } = useGetMemberLabResultsAPI(searchTerm);

  useEffect(() => {
    setFilteredRecords(viewLabResultsList);
  }, [viewLabResultsList]);

  const initialValues = {
    startDate: null,
    endDate: null,
  };

  const resetPage = () => {
    setUserAction(null);
  };

  //Handle OnSubmit for Search
  const onSubmitHandler = (values) => {
    resetPage();
    setUserAction("Search");
    let filterParams = {
      startDate:
        values.startDate && getFormattedDate(values.startDate, DATE_MM_DD_YYYY),
      endDate:
        values.endDate && getFormattedDate(values.endDate, DATE_MM_DD_YYYY),
    };
    setSearchTerm(filterParams);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={formSchema(
          props.fields.lblDateError.value,
          props.fields.lblMinMaxDateError.value
        )}
        onSubmit={onSubmitHandler}
      >
        {(formikProps) => (
          <Form>
            <div
              className="tab-pane fade show active referrals-tab"
              id="labResults"
              role="tabpanel"
              aria-label="tab-1"
            >
              <div className="row no-tab-title" id="medicationDate">
                <div className="col-12">
                  {islabResultsloaded ? (
                    userAction === null &&
                    (filteredRecords === null ||
                      filteredRecords?.length === 0) ? (
                      <>
                        <div className="no-records">
                          <Text field={props.fields.lblZeroLabResultMessage} />
                        </div>
                        <ServiceDescription
                          disclaimer={props.fields.lblDesc1.value}
                          contactUs={props.fields.lnkContactUs}
                          resultsDisclaimer={labResultsDisclaimer}
                          isCABrightcareMember={isCABrightcareMember}
                        />
                      </>
                    ) : (
                      <>
                        <div className="row">
                          <div className="col-md-12 page-subheading mb-4">
                            <Text field={props.fields.lblDatePerformed} />
                          </div>
                        </div>
                        <DisplaySearch
                          props={props}
                          startDateDisclaimer={startDateDisclaimer}
                          formikProps={formikProps}
                          showProvider={false}
                        />

                        <hr />
                        {filteredRecords && filteredRecords.length > 0 ? (
                          <>
                            {
                              <div>
                                <MemberLabResults
                                  fields={props.fields}
                                  data={filteredRecords}
                                />
                                <MemberLabResultsMobile
                                  fields={props.fields}
                                  data={filteredRecords}
                                />
                              </div>
                            }
                          </>
                        ) : (
                          <p className="no-records">
                            <Text field={props.fields.lblNoResults} />
                          </p>
                        )}

                        {printReportDate(props.fields.lblPrintGenerated)}

                        <ServiceDescription
                          disclaimer={props.fields.lblDesc1.value}
                          contactUs={props.fields.lnkContactUs}
                          resultsDisclaimer={labResultsDisclaimer}
                          isCABrightcareMember={isCABrightcareMember}
                        />
                      </>
                    )
                  ) : (
                    filteredRecords?.slice(0).length === 0 && (
                      <li className="list-group-item">
                        {innerSpinnerHTML?.[MY_HEALTH_RECORD_GET_LAB_RESULTS]}
                        <div className="row field1">
                          <div className="col-sm-12"></div>
                        </div>
                      </li>
                    )
                  )}
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default MyHealthRecordLabResults;
