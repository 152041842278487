import React, { useState, useEffect, useContext } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { useAllergiesListAPI } from "../../hooks/useAllergiesListAPI";
import {
  paginationPrint,
  printReportDate,
  // pagination1,
  // paginationWithoutApiCall ,
} from "../Common-Health-Records/Helper";
import AllergiesRecords from "./AllergiesRecords";
import ServiceDescription from "../MyHealthRecord-Layout/Common/ServiceDescription";
import { AppContext } from "../../contexts/app.context";
import {
  MS,
  SC,
  MARKETPLACE,
  NY,
  NE,
  MEDICAID,
  GLOBAL_VARIABLE,
  NON_MP_DISCLAIMER_WAVE3_STATES,
  CA,
  VA,
  WA,
  MA,
  IL,
  OH,
  VA_CCC_MEDICAID_PlanIDS,
  WI,
  WI_MEDICAID_PROGRAMID,
  NM,
  NM_MEDICAID_ProgramID,
  FL,
  FL_SP_PROGRAMID,
  FL_Medicaid_Planid,
  FL_Medicaid_planid1,
  FL_Medicaid_planid2,
  FL_MMA_LTC_PROGRAMID,
} from "../../define.constants";
import { MY_HEALTH_RECORD_GET_ALLERGIES } from "../../define.widgets";
import { SpinnerContext } from "../../contexts/spinner.context";

const MyHealthRecordAllergies = (props) => {
  const [filteredRecords, setFilteredRecords] = useState([]);
  const {
    MemberStateName,
    memberLOB,
    isNYAffinityMember,
    memberStateCode,
    planId,
    programId,
    isCABrightcareMember,
  } = useContext(AppContext);
  const { innerSpinnerHTML } = useContext(SpinnerContext);
  const { allergiesList, isResultsloaded } = useAllergiesListAPI();

  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");

  useEffect(() => {
    setFilteredRecords(allergiesList);
  }, [allergiesList]);
  const isMarketplaceMember =
    memberLOB?.toLowerCase() === MARKETPLACE.toLowerCase();
  const isMedicaidMember = memberLOB?.toLowerCase() === MEDICAID.toLowerCase();

  const resultsDisclaimer = () => {
    // Preparing disclaimer Part 1
    const description1 = isMarketplaceMember
      ? props.fields.lblDescription1?.value
      : props.fields.lblDescriptionNonMp1?.value;

    // Preparing disclaimer Part 2
    let stateNAL = "";

    // States sharing same phoneno in allergies tab disclaimer
    const showNAL = NON_MP_DISCLAIMER_WAVE3_STATES?.includes(
      MemberStateName?.trim()?.toLowerCase()
    );

    if (
      MemberStateName === SC ||
      MemberStateName === CA ||
      MemberStateName === WA ||
      MemberStateName === IL ||
      MemberStateName === OH
    ) {
      stateNAL = props.fields.lblOtherStatesNonMpNAL1?.value;
    } else if (MemberStateName === VA) {
      if (VA_CCC_MEDICAID_PlanIDS.includes(planId)) {
        stateNAL = props.fields.lblVACCCNAL?.value;
      } else {
        stateNAL = props.fields.lblVAMedallionNAL?.value;
      }
    } else if (MemberStateName === MA) {
      stateNAL = props.fields.lblMANAL?.value;
    } else if (
      MemberStateName === NY &&
      memberStateCode === NY &&
      isNYAffinityMember &&
      isNYAffinityMember === true
    )
      stateNAL = props.fields.lblNYAFFNAL?.value;
    else if (MemberStateName === NY && memberStateCode === NY)
      stateNAL = props.fields.lblNYNAL?.value;
    else if (isMarketplaceMember)
      stateNAL = props.fields.lblOtherStatesNAL?.value;
    else if (showNAL) stateNAL = props.fields.lblOtherStatesNonMpNAL1?.value;
    else if (
      isMedicaidMember &&
      MemberStateName === NE &&
      memberStateCode === NE
    )
      stateNAL = props.fields.lblOtherStatesNE?.value;
    else stateNAL = props.fields.lblOtherStatesNonMpNAL?.value;

    // Preparing disclaimer Part 3
    let description2 = "";
    if (isMarketplaceMember) description2 = props.fields.lblDescription2?.value;
    else if (
      MemberStateName === NY &&
      memberStateCode === NY &&
      isNYAffinityMember &&
      isNYAffinityMember === true
    )
      description2 = props.fields.lblDescriptionNYAFF2?.value;
    else if (MemberStateName === NY && memberStateCode === NY)
      description2 = props.fields.lblDescription2?.value;
    else if (
      isMedicaidMember &&
      MemberStateName === FL &&
      (programId === FL_SP_PROGRAMID || programId === FL_MMA_LTC_PROGRAMID) &&
      (planId === FL_Medicaid_Planid ||
        planId === FL_Medicaid_planid1 ||
        planId === FL_Medicaid_planid2)
    )
      description2 = props.fields.lblDescriptionNonMP4?.value;
    else if (showNAL) description2 = props.fields.lblDescriptionNonMP3?.value;
    else if (
      isMedicaidMember &&
      MemberStateName === WI &&
      programId === WI_MEDICAID_PROGRAMID
    )
      stateNAL = props.fields.lblWINAL.value;
    else if (
      isMedicaidMember &&
      MemberStateName === NM &&
      programId === NM_MEDICAID_ProgramID
    )
      stateNAL = props.fields.lblNMNAL.value;
    else description2 = props.fields.lblDescriptionNonMP2?.value;

    if (isCABrightcareMember) stateNAL = props.fields.lblCACHPNAL.value;

    return description1 + stateNAL + description2;
  };

  return (
    <>
      <div
        className="tab-pane fade show active referrals-tab"
        id="allergies"
        role="tabpanel"
        aria-label="tab-1"
      >
        <div className="row no-tab-title">
          <div className="col-md-12 no-date-filter">
            {isResultsloaded ? (
              allergiesList === null || allergiesList?.length === 0 ? (
                <div className="no-records">
                  {isMarketplaceMember ? (
                    <Text field={props.fields.lblZeroAllergyMessage} />
                  ) : (
                    <Text field={props.fields.lblZeroAllergyMessageNonMP} />
                  )}
                </div>
              ) : (
                <>
                  <AllergiesRecords
                    fields={props.fields}
                    data={filteredRecords}
                  />

                  {printReportDate(props.fields.lblPrintGenerated)}
                </>
              )
            ) : (
              filteredRecords?.slice(0).length === 0 && (
                <li className="list-group-item">
                  {innerSpinnerHTML?.[MY_HEALTH_RECORD_GET_ALLERGIES]}
                  <div className="row field1">
                    <div className="col-sm-12"></div>
                  </div>
                </li>
              )
            )}
          </div>
        </div>
        <ServiceDescription
          disclaimer={
            isMarketplaceMember
              ? props.fields.lblDescription.value
              : props.fields.lblDescriptionNonMp.value
          }
          contactUs={props.fields.lnkContactUs}
          resultsDisclaimer={resultsDisclaimer()}
          isCABrightcareMember={isCABrightcareMember}
        />
      </div>
    </>
  );
};

export default MyHealthRecordAllergies;
