import React from "react";
import { IMAGE_PATH } from "../../../../../define.constants";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const ViewIdCardFLPlan = ({ fields, result, details, idCard, drugInfo }) => {
  return (
    <>
      <div
        className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12"
        id="memberIdCardNY"
      >
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/FL/Medicaid/FL_Front_SP_Member_Id_Card_New.png)`,
          }}
          className="background-idcard"
        >
          <table
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
          >
            <tbody>
              <tr>
                <td nowrap="nowrap" style={{ paddingTop: "20px" }}>
                  <b>
                    <Text field={fields.lblMember} />
                  </b>
                  &nbsp; {idCard?.Member_Name}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblMemberNumberFL} />
                  </b>
                  &nbsp; {idCard?.Member_Number}
                </td>
              </tr>

              <tr>
                <td>
                  <b>
                    <Text field={fields.lblDOB} />
                  </b>
                  &nbsp; {idCard?.Member_DOB}
                </td>
              </tr>

              <tr>
                <td>
                  <b>
                    <Text field={fields.lblPcp} />
                  </b>
                  &nbsp; {details?.PCPName_Current}
                </td>
                <td style={{ borderLeft: "1px solid", paddingLeft: 10 }}>
                  <b>
                    <Text field={fields.lblBinFL} />
                  </b>
                  &nbsp; {result?.RxBin}
                </td>
              </tr>

              <tr>
                <td className="member-services-id">
                  <b>
                    <Text field={fields.lblPcpPhoneFL} />
                  </b>
                  &nbsp; {details?.PCPPhone_Current}
                </td>
                <td style={{ borderLeft: "1px solid", paddingLeft: 10 }}>
                  <b>
                    <Text field={fields.lblPCN} />
                  </b>
                  &nbsp; {result?.IdCardValues?.RxPCN}
                </td>
              </tr>

              <tr>
                <td
                  className="member-services-id"
                  style={{ paddingBottom: "20px" }}
                >
                  <b>
                    <Text field={fields.lblPCPAdress} />
                  </b>
                  &nbsp; {idCard?.PCPAddress_Current}
                </td>
                <td style={{ borderLeft: "1px solid", paddingLeft: 10 }}>
                  <b>
                    <Text field={fields.lblGroup} />
                  </b>
                  &nbsp; {result?.IdCardValues?.RxGRP}
                </td>
              </tr>

              <tr>
                <td>
                  <b>
                    <Text field={fields.lblIDBackServices} />
                  </b>{" "}
                  <Text field={fields.lblMemberServicesNumberFL} />
                </td>
              </tr>
              <tr>
                <td>
                  <Text field={fields.lblHours} />{" "}
                  <Text field={fields.lblHoursTime} />
                </td>
              </tr>
              <tr>
                <td>
                  <Text field={fields.lblMessageServiceHoursFL} />
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblIDBackNAL} />
                  </b>
                </td>
              </tr>
              <tr>
                <td style={{ marginTop: 0, paddingTop: 0 }}>
                  <b>
                    <Text field={fields.lblEnglishFL} />
                  </b>
                  <Text field={fields.lblEnglishFLNum} />
                  <b>
                    <Text field={fields.lblSpanishFL} />
                  </b>
                  <Text field={fields.lblSpanishFLNum} />
                  <b>
                    <Text field={fields.lblTTYFL} />
                  </b>
                  <Text field={fields.lblTTYFLNum} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/FL/Medicaid/FL_Back_SP_Member_Id_Card_New.png)`,
          }}
          className="member-id-card-back member-id-card-nm"
          id="FLback"
        >
          <div className="member-id-card-back-text backcard">
            <table
              width="100%"
              border="0"
              cellPadding="0"
              cellSpacing="0"
              id="memberCurrentID"
            ></table>
            <div className="backcardmember">
              <div className="FLbackmb">
                <b>
                  <Text field={fields.lblEmergencyServicesFL} />{" "}
                </b>
                <Text field={fields.lblEmergencyFL} />
              </div>
              <div className="FLbackmb">
                <b>
                  <Text field={fields.lblTeleHealthFL} />
                </b>
                <Text field={fields.lblTeleHealthNumberFL} />
              </div>
              <div className="FLbackmb">
                <b>
                  <Text field={fields.lblCaseFL} />
                </b>
                &nbsp;
                <Text field={fields.lblCaseNumberFL} />
              </div>
              <div className="FLbackmb">
                <b>
                  <Text field={fields.lblClaimFL} />
                </b>
                &nbsp;
                <Text field={fields.lblClaimsInfoFL} />
              </div>
              <div className="FLbackmb">
                <b>
                  <Text field={fields.lblEdiFL} />
                </b>
                &nbsp;
                <Text field={fields.lblEdiNumberFL} />
              </div>
              <div className="FLbackmb">
                <b>
                  <Text field={fields.lblTransportationFL} />
                </b>
                &nbsp;
                <Text field={fields.lblTransportNumberFL} />
              </div>
              <div>
                <Text field={fields.lblFLNewAddress} />
              </div>
              <div>
                <Text field={fields.lblFLAddressNumber} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ViewIdCardFLPlan;
