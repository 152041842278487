import React, { useState, useEffect, useContext } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { useViewInpatientAndEDVisitsAPI } from "../../hooks/useViewInpatientAndEDVisitsAPI";
import { Formik, Form } from "formik";
import InpatientAdmissionsERVisitsRecords from "./InpatientAdmissionsERVisitsRecords";
import { getFormattedDate } from "../../utils/helpers";
import {
  NUMBER_OF_RECORDS_PER_API_CALL,
  MARKETPLACE,
  KY,
  KYMC_PROGRAMID,
  KYMAPD_PROGRAMID,
  MI,
  WA,
  WAFCPRGMID,
  NY,
  NYMCC_PROGRAMID,
  AZ,
  AZMCC_PROGRAMID,
  DUAL,
  DATE_MM_DD_YYYY,
  MEDICAID,
  SC_MEDICAID,
  SC,
  GLOBAL_VARIABLE,
  NM,
  NM_MEDICAID_ProgramID,
  CHP_DATE,
} from "../../define.constants";
import {
  printReportDate,
  paginationPrint,
  pagination,
} from "../Common-Health-Records/Helper";
import { formatName } from "../../utils/helpers";
import InPatientAdmissionMobileRecords from "./InPatientAdmissionMobileRecords";
import ServiceDescription from "../MyHealthRecord-Layout/Common/ServiceDescription";
import DisplaySearch from "../MyHealthRecord-Layout/Common/DisplaySearch";
import { SpinnerContext } from "../../contexts/spinner.context";
import { MY_HEALTH_RECORD_VIEW_INPATIENT_AND_ED_VISITS } from "../../define.widgets";
import * as yup from "yup";
import { FooterDescription } from "../MyHealthRecord-Layout/Common/FooterDescription";
import { AppContext } from "./../../contexts/app.context";

const MyHealthRecordInpatientAdmissionsERVisits = (props) => {
  const recordsPerPage = parseInt(props.fields.lblPostPerPage.value);
  const { innerSpinnerHTML } = useContext(SpinnerContext);

  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props?.fields, "intent");

  /**Pagination useState Variables... */
  const [next, setNext] = useState(recordsPerPage);
  const [page, setPage] = useState(1);
  const [fetchData, setFetchData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecordsCount, setTotalRecordsCount] = useState(0);

  /** Search useState Variables..... */
  const [searchTerm, setSearchTerm] = useState(null);
  const [userAction, setUserAction] = useState(null);

  /**Filtering useState Variables... */
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [slicedRecords, setSlicedRecords] = useState(null);

  /** Dropdown default value "All" */
  const [selectedValue, setSelectedValue] = useState(
    props.fields.lblDefaultDropdownOption.value
  );

  /** Formik implementation..... */
  const intialValues = {
    startDate: null,
    endDate: null,
    selectedOption: selectedValue,
  };

  let {
    memberLOB,
    programId,
    MemberStateName,
    isNYAffinityMember,
    isFIMCMember,
    isDualEligible,
    isCABrightcareMember,
  } = useContext(AppContext);
  const isMarketplaceMember =
    memberLOB.toLowerCase() === MARKETPLACE.toLowerCase();

  const { facilityList, inpatientVisitsList, isImpatientloaded, totalRecords } =
    useViewInpatientAndEDVisitsAPI(
      page,
      NUMBER_OF_RECORDS_PER_API_CALL,
      fetchData,
      searchTerm
    );

  useEffect(() => {
    if (totalRecords > 0) {
      setFilteredRecords((r) =>
        page > 1 ? [...r, ...inpatientVisitsList] : inpatientVisitsList
      );
      setTotalRecordsCount(totalRecords);
      /* To show pagination label count correctly as it is not coming as correct from API for Search*/
      // if (totalRecords < recordsPerPage)
      //   setTotalRecordsCount(inpatientVisitsList.length);
      // else setTotalRecordsCount(totalRecords);
      /* end pagination label logic */
    }
  }, [inpatientVisitsList, totalRecords, page]);

  useEffect(() => {
    let slicedRecords = filteredRecords;
    const offset = (currentPage - 1) * recordsPerPage;
    slicedRecords = filteredRecords?.slice(0, offset + recordsPerPage);
    if (totalRecordsCount - offset < recordsPerPage) {
      slicedRecords = filteredRecords?.slice(
        0,
        offset + (totalRecordsCount - offset)
      );
    } else {
      slicedRecords = filteredRecords?.slice(0, offset + recordsPerPage);
    }

    setSlicedRecords(slicedRecords);
  }, [filteredRecords, currentPage, totalRecordsCount]);

  // filtering useState for pagination //commented out cause filter is not working in use effect
  let totalPages = Math.ceil(totalRecordsCount / recordsPerPage);

  const dropdownOptions = [
    {
      value: props.fields.lblDefaultDropdownOption.value,
      id: props.fields.lblDefaultDropdownOption.value,
      label: props.fields.lblDefaultDropdownOption.value,
    },
  ];

  /* Mapping from API.......... */
  facilityList?.map((data) => {
    dropdownOptions.push({
      value: data.Value,
      id: data.Value,
      label: formatName(data.Text),
    });
  });

  const handleDropdownChange = (selectedOption) => {
    setSelectedValue(selectedOption.value);
  };

  const resetPage = () => {
    setPage(1);
    setCurrentPage(1);
    setUserAction(null);
  };

  const onSubmitHandler = (values) => {
    resetPage();
    setUserAction("Search");
    let filterParams = {
      FilterParams: `admitFrom=${
        values.startDate
          ? getFormattedDate(values.startDate, DATE_MM_DD_YYYY)
          : ""
      };dischargeTo=${
        values.endDate ? getFormattedDate(values.endDate, DATE_MM_DD_YYYY) : ""
      };provId=${
        values.selectedOption === props.fields.lblDefaultDropdownOption.value
          ? ""
          : values.selectedOption
      };isFilter=${
        !values.startDate &&
        !values.endDate &&
        values.selectedOption === props.fields.lblDefaultDropdownOption.value
          ? 0
          : 1
      }`,
    };
    setSearchTerm(filterParams);
    setFetchData(true);
  };

  const formSchema = (...dateError) => {
    return yup.object().shape(
      {
        startDate: yup.date().when("endDate", {
          is: (endDate) => endDate != null,
          then: yup
            .date()
            .max(yup.ref("endDate"), dateError[0])
            .typeError(dateError[1])
            .nullable(),
          otherwise: yup.date().typeError(dateError[1]).nullable(),
        }),
      },
      [["startDate", "endDate"]]
    );
  };

  const getDisclaimerMessage = (fields) => {
    const isWASateMember = MemberStateName?.toLowerCase() === WA.toLowerCase();
    const isMISateMember = MemberStateName?.toLowerCase() === MI.toLowerCase();
    const isNYSateMember = MemberStateName?.toLowerCase() === NY.toLowerCase();
    const isAZSateMember = MemberStateName?.toLowerCase() === AZ.toLowerCase();
    // const isNMSateMember = MemberStateName?.toLowerCase() === NM.toLowerCase();
    // console.log(MemberStateName);
    const isSCMedicaid =
      memberLOB?.toLowerCase() === MEDICAID &&
      MemberStateName === SC &&
      programId === SC_MEDICAID;
    let disclaimerFullMessage = null;
    let disclaimerMessageFirstPart = null;
    let formatDate = isMarketplaceMember ? "mm/dd/yy" : "mm/dd/yyyy";
    let disclaimerMessageDate = getFormattedDate(new Date(), formatDate);
    let disclaimerMessageSecondPart = null;
    let disclaimerMessageThirdPart = FooterDescription(props.fields);

    if (isMarketplaceMember) {
      disclaimerMessageFirstPart = props.fields.lblDisclaimer1.value;
      disclaimerMessageSecondPart = props.fields.lblDisclaimer2.value;
    } else if (isMISateMember && isDualEligible && isDualEligible === DUAL) {
      disclaimerMessageFirstPart =
        props.fields.lblDisclaimerMIDualsPrefix.value;
      disclaimerMessageSecondPart =
        props.fields.lblDisclaimerMIDualsSuffix.value;
      disclaimerMessageThirdPart = "";
    } else if (isWASateMember) {
      disclaimerMessageFirstPart = props.fields.lblDisclaimerWAPrefix.value;
      disclaimerMessageSecondPart = props.fields.lblDisclaimerWASuffix.value;
      disclaimerMessageThirdPart = "";
      disclaimerMessageDate = "";
    } else if (isNYSateMember && programId == NYMCC_PROGRAMID) {
      disclaimerMessageFirstPart =
        props.fields.lblDisclaimerNYMedicaidPrefix.value;
      disclaimerMessageSecondPart =
        props.fields.lblDisclaimerNYMedicaidSuffix.value;
      disclaimerMessageThirdPart = "";
    } else if (
      MemberStateName === NM &&
      programId == NM_MEDICAID_ProgramID &&
      memberLOB?.toLowerCase() === MEDICAID
    ) {
      disclaimerMessageFirstPart = props.fields.lblDisclaimerNMPrefix.value;
      disclaimerMessageSecondPart = props.fields.lblDisclaimerNMSuffix.value;
      disclaimerMessageThirdPart = "";
    } else if (isAZSateMember && programId == AZMCC_PROGRAMID) {
      disclaimerMessageFirstPart = props.fields.lblDisclaimerAZPrefix.value;
      disclaimerMessageSecondPart = props.fields.lblDisclaimerAZSuffix.value;
      disclaimerMessageThirdPart = "";
    } else if (
      isNYSateMember &&
      isNYAffinityMember &&
      isNYAffinityMember === true
    ) {
      disclaimerMessageFirstPart = props.fields.lblDisclaimerNYPreffix.value;
      disclaimerMessageSecondPart = props.fields.lblDisclaimerNYSuffix.value;
      disclaimerMessageThirdPart = "";
    } else if (isCABrightcareMember) {
      disclaimerMessageFirstPart = props.fields.lblDisclaimerNonMP1.value;
      disclaimerMessageSecondPart = props.fields.lblDisclaimerCACHPSuffix.value;
      disclaimerMessageThirdPart = "";
    } else {
      disclaimerMessageFirstPart = props.fields.lblDisclaimerNonMP1.value;
      disclaimerMessageSecondPart = props.fields.lblDisclaimerNonMP2.value;
    }
    if (isSCMedicaid) {
      disclaimerFullMessage =
        disclaimerMessageFirstPart +
        "(" +
        disclaimerMessageDate +
        ")" +
        disclaimerMessageSecondPart +
        disclaimerMessageThirdPart;
    } else {
      disclaimerFullMessage =
        disclaimerMessageFirstPart +
        disclaimerMessageDate +
        disclaimerMessageSecondPart +
        disclaimerMessageThirdPart;
    }
    return disclaimerFullMessage;
  };
  const getKYBannerMsg = (fields) => {
    let bannerMsgKY = null;
    if (MemberStateName?.toLowerCase() === KY.toLowerCase()) {
      if (programId === KYMC_PROGRAMID) {
        bannerMsgKY = fields?.lblDSNPMedicareMsgKY;
      } else if (programId === KYMAPD_PROGRAMID) {
        bannerMsgKY = fields?.lblMedicareMsgKY;
      } else {
        bannerMsgKY = null;
      }
    }
    return bannerMsgKY;
  };

  return (
    <>
      <Formik
        initialValues={intialValues}
        onSubmit={onSubmitHandler}
        validationSchema={formSchema(
          props.fields.lblInvalidAdmitDate.value,
          props.fields.lblInvalidDateType.value
        )}
      >
        {(formikProps) => (
          <Form>
            <div
              className="tab-pane fade show active referrals-tab"
              id="tab_inpatienAdm"
              role="tabpanel"
              aria-label="tab-7"
            >
              <div className="row no-tab-title" id="inpatienAdm">
                <div className="col-12">
                  {isImpatientloaded ? (
                    userAction === null &&
                    (slicedRecords === null || slicedRecords?.length === 0) ? (
                      <div className="no-records">
                        <Text field={props.fields.lblZeroMessage1} />
                        {props.fields.lblZeroMessage2.value +
                          FooterDescription(props.fields)}
                      </div>
                    ) : (
                      <>
                        <div className="row">
                          <div className="col-md-12 page-subheading d-sm-none d-md-block">
                            <Text field={props.fields.Title} />
                          </div>
                        </div>
                        <DisplaySearch
                          providers={facilityList}
                          handleDropdownChange={handleDropdownChange}
                          dropdownOptions={dropdownOptions}
                          props={props}
                          formikProps={formikProps}
                          showProvider={true}
                          minDate={isCABrightcareMember ? CHP_DATE : ""}
                        />

                        <hr />

                        {slicedRecords && slicedRecords.length > 0 ? (
                          <>
                            {paginationPrint(
                              totalRecordsCount,
                              next,
                              props.fields
                            )}

                            <InpatientAdmissionsERVisitsRecords
                              fields={props.fields}
                              data={slicedRecords}
                              next={next}
                            />

                            <InPatientAdmissionMobileRecords
                              fields={props.fields}
                              data={slicedRecords}
                              next={next}
                            />
                            {totalPages !== currentPage && (
                              <a
                                className="show-more hr-contact"
                                onClick={(e) =>
                                  pagination(
                                    totalRecordsCount,
                                    next,
                                    page,
                                    recordsPerPage,
                                    setNext,
                                    setPage,
                                    currentPage,
                                    setCurrentPage,
                                    NUMBER_OF_RECORDS_PER_API_CALL,
                                    setFetchData
                                  )
                                }
                              >
                                <Text field={props.fields.lblShowMore}></Text>
                              </a>
                            )}
                          </>
                        ) : (
                          <div className="no-records">
                            <Text field={props.fields.lblSearchDisclaimer} />
                          </div>
                        )}
                        {printReportDate(props.fields.lblReport)}
                      </>
                    )
                  ) : (
                    slicedRecords?.slice(0).length === 0 && (
                      <li className="list-group-item">
                        {
                          innerSpinnerHTML?.[
                            MY_HEALTH_RECORD_VIEW_INPATIENT_AND_ED_VISITS
                          ]
                        }
                        <div className="row field1">
                          <div className="col-sm-12"></div>
                        </div>
                      </li>
                    )
                  )}
                </div>
              </div>
              <ServiceDescription
                bannerMsgKY={getKYBannerMsg(props.fields)}
                disclaimer={getDisclaimerMessage(props.fields)}
                contactUs={props.fields.lnkContactUs}
                isCABrightcareMember={isCABrightcareMember}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default MyHealthRecordInpatientAdmissionsERVisits;
