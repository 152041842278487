import { useEffect, useState, useContext } from "react";
import { AppContext } from "../contexts/app.context";
import { ErrorContext } from "../contexts/error.context";
import * as CommonAPI from "../services/Common";
import {
  ICON_PATH,
  SPANISH_LANG_CODE,
  CHINESE_LANG_CODE,
  ENGLISH_LANG_CODE,
  SPANISH_LANG_TEXT,
  CHINESE_LANG_TEXT,
} from "../define.constants";

const useGetDictionaryItems = () => {
  const [distItem, SetDistItem] = useState(null);
  let { prefLang } = useContext(AppContext);
  const { setError } = useContext(ErrorContext);
  useEffect(() => {
    if (prefLang !== "" && prefLang !== null) {
      // console.log(prefLang, "preflan");
      const langcode =
        SPANISH_LANG_TEXT.toLowerCase() === prefLang.toLowerCase() ||
        SPANISH_LANG_CODE.toLocaleLowerCase() === prefLang.toLocaleLowerCase()
          ? SPANISH_LANG_CODE
          : CHINESE_LANG_TEXT.toLowerCase() === prefLang.toLowerCase() ||
            CHINESE_LANG_CODE.toLocaleLowerCase() ===
              prefLang.toLocaleLowerCase()
          ? CHINESE_LANG_CODE
          : ENGLISH_LANG_CODE;
      CommonAPI.getDictionaryItems(langcode)
        .then((response) => {
          SetDistItem(response);
        })
        .catch(function (err) {
          setError({ err });
        })
        .finally(function () {});
    }
  }, [prefLang]);

  return {
    distItem,
    SetDistItem,
  };
};

export { useGetDictionaryItems };
