import React, { useContext } from "react";
import { AppContext } from "../../contexts/app.context";
import {
  CA,
  NY_MD_EP_PROGRAMID_LIST,
  WA_MD_IMCCommon_LIST,
  MEDICAID,
  MEDICARE,
  NY,
  WA,
  UT,
  VA,
  OH,
  IL,
  COMMON,
  ZERO,
  FAMIS,
  FAMIS_M4,
  RISE,
  FULL,
  PARTIAL,
  STANDARD,
  CSP_FULL,
  CSP_PARTIAL,
  MCCare_PROGRAMID,
  PLAN_MEDICARE_CHOICECARE,
  NM_MEDICARE_IMAGE,
  AZ_MEDICAID_IMAGE,
  KY_MEDICAID_IMAGE,
  NV_MEDICAID_IMAGE,
  NY_MEDICAID_HARP_AFFINITY_IMAGE,
  NY_MEDICAID_HARP_NONAFFINITY_IMAGE,
  NY_MEDICAID_CHIP_IMAGE,
  NY_MEDICAID_MLTC_IMAGE,
  NY_MEDICAID_COMMON_ESSENTIAL_AFFINITY_IMAGE,
  NY_MEDICAID_COMMON_ESSENTIAL_NONAFFINITY_IMAGE,
  UT_MEDICAID_IMAGE,
  WA_MEDICAID_BHSO_IMAGE,
  WA_MEDICAID_COMMON_IMAGE,
  WI_MEDICAID_IMAGE,
  AZ_MEDICARE_IMAGE,
  KY_MEDICARE_DSNP_IMAGE,
  KY_MEDICARE_CHOICECARE_IMAGE,
  NV_MEDICARE_IMAGE,
  NY_MEDICARE_IMAGE,
  UT_MEDICARE_HAP_IMAGE,
  UT_MEDICARE_COMMON_IMAGE,
  WA_MEDICARE_IMAGE,
  WI_MEDICARE_IMAGE,
  NY_MD_HARP_PROGRAMID,
  NY_MD_NMC_PROGRAMID,
  NY_MEDICAID_MCC_AFFINITY_IMAGE,
  NY_MEDICAID_MCC_NONAFFINITY_IMAGE,
  MS_MEDICAID_MSCAN_IMAGE,
  MS_MEDICAID_CHIP_IMAGE,
  FL_MEDICAID_SPECIALTY_PLAN_IMAGE,
  FL_MEDICAID_LONG_TERM_CARE_IMAGE,
  FL_MEDICAID_MMA_IMAGE,
  FL_MEDICAID_MMA_MMALTC_IMAGE,
  COMMON_MEDICARE_IMAGE,
  FL_MEDICARE_IMAGE,
  TX_MEDICAID_CHIP_RSA_IMAGE,
  TX_MEDICAID_CPR_IMAGE,
  TX_MEDICAID_CHIP_PERINATE_IMAGE,
  TX_MEDICAID_STAR_IMAGE,
  TX_MEDICAID_CHIP_IMAGE,
  TX_MEDICAID_STAR_PLUS_IMAGE,
  TX_DUAL_IMAGE,
  TX_MEDICARE_IMAGE,
  ID_MEDICAID_IMAGE,
  ID_DUAL_IMAGE,
  ID_MEDICARE_COAPY_IMAGE,
  ID_MEDICARE_NOCOAPY_IMAGE,
  MI_MEDICAID_IMAGE,
  MI_DUAL_IMAGE,
  MI_MEDICARE_IMAGE,
  SC_MEDICAID_IMAGE,
  SC_MEDICARE_IMAGE,
  SC_DUAL_IMAGE,
  IL_MEDICAID_IMAGE,
  IL_MEDICAID_HBI_IMAGE,
  IL_MEDICAID_MLTSS_IMAGE,
  IL_MEDICARE_IMAGE,
  IL_DUAL_IMAGE,
  VA_MEDICAID_IMAGE,
  VA_MEDICAID_FAMIS_IMAGE,
  VA_MEDICARE_IMAGE,
  MA_MEDICAID_IMAGE,
  MA_MEDICARE_IMAGE,
  CA_MEDICAID_IMAGE,
  CA_MEDICARE_IMAGE,
  CA_DUAL_IMAGE,
  OH_MCAID_RISE_FULL_IMAGE,
  OH_MCAID_RISE_PARTIAL_IMAGE,
  OH_MCAID_RISE_STANDARD_IMAGE,
  OH_MCAID_FULL_IMAGE,
  OH_MCAID_PARTIAL_IMAGE,
  OH_MCAID_STANDARD_IMAGE,
  OH_DUAL_IMAGE,
  OH_MEDICARE_IMAGE,
  NE_MEDICAID_IMAGE,
  IL_MD_HBI_PROGRAMID_LIST,
  NM_MEDICAID_IMAGE,
  MS_MEDICARE_IMAGE_QMXREG010005,
  NE_MEDICARE_IMAGE_QMXUN32300,
  NM_MEDICARE_IMAGE_QMXREG020007,
  VA_MEDICARE_HOME_IMAGE,
  CA_MEDICARE_BRIGHT_EAE,
  CA_MEDICARE_BRIGHT_NonEAE,
  CA_MEDICARE_BRIGHT_NonEAE_NoPartD,
  plan_ca_chp_eae,
  plan_ca_chp_non_eae,
  plan_ca_chp_non_eae_no_part_d,
  CA_MEDICARE_BRIGHT_CARE_PROGRAM,
  FL_MEDICAID_IMAGE_SP,
  FL_Home_Medicaid_IDCard_LTC,
  FL,
  FL_SP_PROGRAMID,
  FL_MMA_LTC_PROGRAMID,
  FL_Medicaid_Planid,
  FL_Medicaid_planid1,
  FL_Medicaid_planid2,
} from "../../define.constants";

export const StateLOBImagePath = (
  memberState,
  memberLOB,
  programID,
  isCABrightcareMember
) => {
  const memberState_Updated = memberState
    ? memberState.trim().toLowerCase()
    : null;

  const {
    planId,
    isNYAffinityMember,
    isILHBIMember,
    VA_Category,
    IsOHIORISE,
    OH_CSPValue,
  } = useContext(AppContext);
  const memberLOB_Updated = memberLOB ? memberLOB.trim().toLowerCase() : null;
  const programID_Updated = programID ? programID.trim() : null;
  const planId_Updated = planId ? planId.trim() : null;

  //Dictionary of IDCard image path
  const homeIDCardCollection = {
    // Wave 1 - Medicare NM
    nm_medicare_0: NM_MEDICARE_IMAGE,

    // Medicaid - Wave 2
    // States - AZ, KY, NV, NY, UT, WA, WI
    az_medicaid_0: AZ_MEDICAID_IMAGE,
    ky_medicaid_0: KY_MEDICAID_IMAGE,
    nv_medicaid_0: NV_MEDICAID_IMAGE,
    ny_medicaid_QMT2HPQ0155_AFFINITY: NY_MEDICAID_MCC_AFFINITY_IMAGE,
    ny_medicaid_QMT2HPQ0155_NONAFFINITY: NY_MEDICAID_MCC_NONAFFINITY_IMAGE,
    ny_medicaid_QMT2HPQ0166_AFFINITY: NY_MEDICAID_HARP_AFFINITY_IMAGE,
    ny_medicaid_QMT2HPQ0166_NONAFFINITY: NY_MEDICAID_HARP_NONAFFINITY_IMAGE,
    ny_medicaid_COMMON_ESSENTIAL_AFFINITY:
      NY_MEDICAID_COMMON_ESSENTIAL_AFFINITY_IMAGE,
    ny_medicaid_COMMON_ESSENTIAL_NONAFFINITY:
      NY_MEDICAID_COMMON_ESSENTIAL_NONAFFINITY_IMAGE,
    ny_medicaid_PGM0000000015: NY_MEDICAID_CHIP_IMAGE,
    ny_medicaid_PGM0000000027: NY_MEDICAID_MLTC_IMAGE,
    ut_medicaid_0: UT_MEDICAID_IMAGE,
    wi_medicaid_0: WI_MEDICAID_IMAGE,

    // Wave 3 - Medicaid - MS, FL, TX, CA, ID, MI
    //MS
    ms_medicaid_QMXHPQ2287: MS_MEDICAID_MSCAN_IMAGE,
    ms_medicaid_QMXHPQ2289: MS_MEDICAID_CHIP_IMAGE,

    //FL
    fl_medicaid_PGM0000000040: FL_MEDICAID_SPECIALTY_PLAN_IMAGE,
    fl_medicaid_QMXHPQ2224: FL_MEDICAID_LONG_TERM_CARE_IMAGE,
    fl_medicaid_QMXHPQ1268: FL_MEDICAID_MMA_IMAGE,
    fl_medicaid_QMXHPQ2244: FL_MEDICAID_MMA_MMALTC_IMAGE,
    fl_medicaid_qmxbpreg030003: FL_MEDICAID_IMAGE_SP,
    fl_medicaid_qmxbpreg030005: FL_Home_Medicaid_IDCard_LTC,
    fl_medicaid_qmxbpreg030006: FL_Home_Medicaid_IDCard_LTC,

    //TX
    tx_medicaid_TQPROG1814: TX_MEDICAID_CHIP_RSA_IMAGE,
    tx_medicaid_TQPROG1815: TX_MEDICAID_CPR_IMAGE,
    tx_medicaid_QMXHPQ2141: TX_MEDICAID_CHIP_PERINATE_IMAGE,
    tx_medicaid_QMXHPQ0879: TX_MEDICAID_STAR_IMAGE,
    tx_medicaid_QMXHPQ0881: TX_MEDICAID_CHIP_IMAGE,
    tx_medicaid_QMXHPQ0882: TX_MEDICAID_STAR_PLUS_IMAGE,

    //ID
    id_medicaid_0: ID_MEDICAID_IMAGE,

    //MI
    mi_medicaid_TQMXHPQ1628: MI_MEDICAID_IMAGE,

    // Wave 3.1 - Medicaid - SC, IL, VA, MA, WA, CA, OH
    // SC
    sc_medicaid_0: SC_MEDICAID_IMAGE,

    // IL
    il_medicaid_PGM0000000015: IL_MEDICAID_IMAGE,
    il_medicaid_QMXHPQ0855: IL_MEDICAID_IMAGE,
    il_medicaid_COMMON_HBI: IL_MEDICAID_HBI_IMAGE,
    il_medicaid_QMXHPQ0851: IL_MEDICAID_MLTSS_IMAGE,

    // VA
    va_medicaid_0: VA_MEDICAID_IMAGE,
    va_medicaid_0_FAMIS: VA_MEDICAID_FAMIS_IMAGE,
    va_medicare_0: VA_MEDICARE_HOME_IMAGE,

    // MA
    ma_medicaid_0: MA_MEDICAID_IMAGE,

    // WA
    wa_medicaid_QMXHPQ2273: WA_MEDICAID_BHSO_IMAGE,
    wa_medicaid_COMMON: WA_MEDICAID_COMMON_IMAGE,

    // CA
    ca_medicaid_0: CA_MEDICAID_IMAGE,

    // OH
    oh_medicaid_0_FULL: OH_MCAID_FULL_IMAGE,
    oh_medicaid_0_PRTL: OH_MCAID_PARTIAL_IMAGE,
    oh_medicaid_0_STNRD: OH_MCAID_STANDARD_IMAGE,
    oh_medicaid_0_RISE_FULL: OH_MCAID_RISE_FULL_IMAGE,
    oh_medicaid_0_RISE_PRTL: OH_MCAID_RISE_PARTIAL_IMAGE,
    oh_medicaid_0_RISE_STNRD: OH_MCAID_RISE_STANDARD_IMAGE,

    // Medicare - Wave 2
    // States - AZ, KY, NV, NY, UT, WA, WI
    az_medicare_0: AZ_MEDICARE_IMAGE,
    ky_medicare_QMXHPQ2305: KY_MEDICARE_DSNP_IMAGE,
    ky_medicare_QMXHPQ2315: KY_MEDICARE_CHOICECARE_IMAGE,
    nv_medicare_0: NV_MEDICARE_IMAGE,
    ny_medicare_0: NY_MEDICARE_IMAGE,
    ut_medicare_QMXHPQ1682_QMXBP8373: UT_MEDICARE_HAP_IMAGE,
    ut_medicare_0: UT_MEDICARE_COMMON_IMAGE,
    wi_medicare_0: WI_MEDICARE_IMAGE,
    ia_medicaid_qmxbp8417: "/id/NonMP/IA/Medicaid/IA_Medicaid_IHL.png",
    ia_medicaid_qmxbp8418: "/id/NonMP/IA/Medicaid/Hawki-v8.png",
    ia_medicaid_qmxbp8419: "/id/NonMP/IA/Medicaid/IA_Medicaid_IHL.png",

    // Wave 3 - Medicare/DUALS - MS, FL, TX, CA, ID, MI
    fl_medicare_0: FL_MEDICARE_IMAGE,
    tx_dual_0: TX_DUAL_IMAGE,
    tx_medicare_0: TX_MEDICARE_IMAGE,
    id_dual_0: ID_DUAL_IMAGE,
    id_medicare_QMXHPQ1682: ID_MEDICARE_COAPY_IMAGE,
    id_medicare_QMXHPQ1683: ID_MEDICARE_NOCOAPY_IMAGE,
    mi_dual_0: MI_DUAL_IMAGE,
    mi_medicare_0: MI_MEDICARE_IMAGE,

    // Wave 3.1 - Medicare/DUALS - SC, IL, VA, MA, WA, CA, OH
    sc_medicare_0: SC_MEDICARE_IMAGE,
    sc_dual_0: SC_DUAL_IMAGE,
    il_medicare_0: IL_MEDICARE_IMAGE,
    il_dual_0: IL_DUAL_IMAGE,
    // va_medicare_0: VA_MEDICARE_IMAGE,
    ma_medicare_0: MA_MEDICARE_IMAGE,
    wa_medicare_0: WA_MEDICARE_IMAGE,
    ca_medicare_0: CA_MEDICARE_IMAGE,
    ca_dual_0: CA_DUAL_IMAGE,
    oh_medicare_0: OH_MEDICARE_IMAGE,
    oh_dual_0: OH_DUAL_IMAGE,
    ne_medicaid_0: NE_MEDICAID_IMAGE,
    nm_medicaid_0: NM_MEDICAID_IMAGE,

    //CY2025 - Medicare - NE, NM, MS
    nm_medicare_QMXREG020007: NM_MEDICARE_IMAGE_QMXREG020007,
    ne_medicare_QMXUN32300: NE_MEDICARE_IMAGE_QMXUN32300,
    ms_medicare_QMXREG010005: MS_MEDICARE_IMAGE_QMXREG010005,

    //CA Bright Care
    ca_medicare_QMXREG020006_eae: CA_MEDICARE_BRIGHT_EAE,
    ca_medicare_QMXREG020006_non_eae: CA_MEDICARE_BRIGHT_NonEAE,
    ca_medicare_QMXREG020006_non_eae_no_part_d:
      CA_MEDICARE_BRIGHT_NonEAE_NoPartD,
  };

  let imagePath = null;
  let isMedicaidMember = memberLOB_Updated === MEDICAID ? true : false;
  let isMedicareMember = memberLOB_Updated === MEDICARE ? true : false;
  const stateListMedicaidPrograimID = [
    "wa",
    "ny",
    "ms",
    "fl",
    "tx",
    "mi",
    "il",
  ];
  const stateListMedicarePrograimID = ["ky", "id", "nm", "ne", "ms"];

  // For these states and lobs consider programIDs to pick IDCard image.
  const doConsiderProgramID =
    (isMedicaidMember &&
      stateListMedicaidPrograimID.includes(memberState_Updated)) ||
    (isMedicareMember &&
      stateListMedicarePrograimID.includes(memberState_Updated))
      ? true
      : false;

  // For a particular state and lob and multiple ProgramIDs sharing same IDCard image
  const isCommonIDCardForProgramIDs =
    isMedicaidMember &&
    ((memberState_Updated === NY?.toLowerCase() &&
      NY_MD_EP_PROGRAMID_LIST.includes(programID_Updated)) ||
      (memberState_Updated === WA?.toLowerCase() &&
        WA_MD_IMCCommon_LIST.includes(programID_Updated)) ||
      (memberState_Updated === IL?.toLowerCase() &&
        IL_MD_HBI_PROGRAMID_LIST.includes(programID_Updated) &&
        isILHBIMember))
      ? true
      : false;

  // Plan Level Check scenario
  const doConsiderPlanID =
    isMedicareMember &&
    memberState_Updated === UT?.toLowerCase() &&
    programID_Updated === MCCare_PROGRAMID &&
    planId_Updated === PLAN_MEDICARE_CHOICECARE;
  //FL PlanID check

  const FLdoConsiderPlanID =
    ((isMedicaidMember &&
      memberState_Updated === FL?.toLowerCase() &&
      programID_Updated === FL_SP_PROGRAMID) ||
      FL_MMA_LTC_PROGRAMID) &&
    (planId_Updated === FL_Medicaid_planid1 ||
      planId_Updated === FL_Medicaid_planid2 ||
      planId_Updated === FL_Medicaid_Planid);

  // Main Check Starts here
  if (memberState && memberLOB) {
    // Key value prepared - State_LOB format
    let state_LOB_ProgramID = memberState_Updated + "_" + memberLOB_Updated;

    // Key value prepared concatinated ProgramID or PlanID conditionally
    if (doConsiderProgramID && programID) {
      state_LOB_ProgramID = isCommonIDCardForProgramIDs
        ? state_LOB_ProgramID + "_" + COMMON //Format Example: NY_MEDICAID_COMMON (Scenario: 5 programIDs sharing 1 IDCARD)
        : state_LOB_ProgramID + "_" + programID_Updated; //Format Example: NY_MEDICAID_12345678
    } else if (doConsiderPlanID && programID && planId) {
      state_LOB_ProgramID =
        state_LOB_ProgramID + "_" + programID_Updated + "_" + planId_Updated; //Format Example: UT_MEDICAID_123456_9876
    } else if (FLdoConsiderPlanID && programID && planId) {
      state_LOB_ProgramID =
        state_LOB_ProgramID + "_" + programID_Updated + "_" + planId_Updated;
    } else {
      state_LOB_ProgramID = state_LOB_ProgramID + "_" + ZERO; //Format Example: AZ_MEDICAID_0
    }

    // For OH Medicaid - Rise/NON-Rise image pulling key update
    if (memberState_Updated === OH?.toLowerCase() && isMedicaidMember) {
      // Checking OH-RISE or not
      if (IsOHIORISE) {
        state_LOB_ProgramID = state_LOB_ProgramID + RISE; // oh_medicaid_0_RISE
      }

      // Checking type - "Full", "Partial", "Standard"
      let IDCardType = "";
      switch (OH_CSPValue) {
        case CSP_FULL:
          IDCardType = FULL; // oh_medicaid_0_RISE_FULL OR oh_medicaid_0_FULL
          break;
        case CSP_PARTIAL:
          IDCardType = PARTIAL; // oh_medicaid_0_RISE_PRTL OR oh_medicaid_0_PRTL
          break;
        default:
          IDCardType = STANDARD; // oh_medicaid_0_RISE_STNRD OR oh_medicaid_0_STNRD
      }

      state_LOB_ProgramID = state_LOB_ProgramID + IDCardType;
    }

    // For VA FAMIS - image pulling Key update
    // E.g. VA Medicaid Non-FAMIS - Key -> va_medicaid_0
    // E.g. VA Medicaid FAMIS - Key -> va_medicaid_0_FAMIS
    if (
      isMedicaidMember &&
      memberState_Updated === VA?.toLowerCase() &&
      VA_Category?.trim() === FAMIS_M4
    ) {
      state_LOB_ProgramID = state_LOB_ProgramID + FAMIS;
    }

    //NY Essential Affinity/NON_Affinity image pulling Key perparation
    if (memberState_Updated === NY?.toLowerCase()) {
      if (isCommonIDCardForProgramIDs) {
        if (isNYAffinityMember) {
          state_LOB_ProgramID = state_LOB_ProgramID + "_ESSENTIAL_AFFINITY";
        } else {
          state_LOB_ProgramID = state_LOB_ProgramID + "_ESSENTIAL_NONAFFINITY";
        }
      } else if (
        programID_Updated === NY_MD_HARP_PROGRAMID ||
        programID_Updated === NY_MD_NMC_PROGRAMID
      ) {
        if (isNYAffinityMember) {
          state_LOB_ProgramID = state_LOB_ProgramID + "_AFFINITY";
        } else {
          state_LOB_ProgramID = state_LOB_ProgramID + "_NONAFFINITY";
        }
      } else {
        //Nothing to implement
      }
    }

    //IL Medicaid HBI image pulling Key perparation
    if (memberState_Updated === IL?.toLowerCase()) {
      if (isCommonIDCardForProgramIDs) {
        if (isILHBIMember) {
          state_LOB_ProgramID = state_LOB_ProgramID + "_HBI";
        }
      } else {
        //Nothing to implement
      }
    }

    let State_LOB =
      memberState?.trim()?.toLowerCase() +
      "_" +
      memberLOB?.trim()?.toLowerCase() +
      "_" +
      planId?.trim()?.toLowerCase();

    if (
      isCABrightcareMember &&
      memberState_Updated == CA.toLowerCase() &&
      memberLOB_Updated == MEDICARE.toLowerCase() &&
      programID_Updated == CA_MEDICARE_BRIGHT_CARE_PROGRAM
    ) {
      if (plan_ca_chp_eae.includes(planId_Updated)) {
        state_LOB_ProgramID =
          memberState?.trim()?.toLowerCase() +
          "_" +
          memberLOB?.trim()?.toLowerCase() +
          "_" +
          programID_Updated +
          "_eae";
      } else if (plan_ca_chp_non_eae.includes(planId_Updated)) {
        state_LOB_ProgramID =
          memberState?.trim()?.toLowerCase() +
          "_" +
          memberLOB?.trim()?.toLowerCase() +
          "_" +
          programID_Updated +
          "_non_eae";
      } else if (plan_ca_chp_non_eae_no_part_d.includes(planId_Updated)) {
        state_LOB_ProgramID =
          memberState?.trim()?.toLowerCase() +
          "_" +
          memberLOB?.trim()?.toLowerCase() +
          "_" +
          programID_Updated +
          "_non_eae_no_part_d";
      }
    }

    if (memberState === "IA") {
      // Pulling image path from dictionary based on key
      imagePath = homeIDCardCollection[State_LOB]
        ? homeIDCardCollection[State_LOB]
        : null;
    } else if (
      memberState === "FL" &&
      (programID === FL_SP_PROGRAMID || programID === FL_MMA_LTC_PROGRAMID) &&
      (planId === FL_Medicaid_Planid ||
        planId === FL_Medicaid_planid1 ||
        planId === FL_Medicaid_planid2)
    ) {
      // Pulling image path from dictionary based on key
      imagePath = homeIDCardCollection[State_LOB]
        ? homeIDCardCollection[State_LOB]
        : null;
    } else {
      // Pulling image path from dictionary based on key
      imagePath = homeIDCardCollection[state_LOB_ProgramID]
        ? homeIDCardCollection[state_LOB_ProgramID]
        : null;
    }

    return imagePath;
  }

  return imagePath;
};
