import React, { useContext, useState } from "react";
import {
  Text,
  getFieldValue,
  RichText,
} from "@sitecore-jss/sitecore-jss-react";
import { getStateConfiguration } from "./../../utils/helpers";
import { AppContext } from "../../contexts/app.context";
import { CA, MEDICAID, MARKETPLACE } from "../../define.constants";

const displayAccordionItems = (list, expandedIndex, setExpandedIndex) => {
  return (
    <div className="accordion" id="wellnessBenefitsAccordion">
      {Object.keys(list).map((key, index) => {
        const isExpanded = expandedIndex === index;

        return (
          <div className="accordion-item cards-tab3" key={index}>
            <h2 className="accordion-header" id={`heading${index}`}>
              <button
                className={`accordion-button ${isExpanded ? "" : "collapsed"}`}
                type="button"
                onClick={
                  () => setExpandedIndex(isExpanded ? null : index) // Toggle the accordion
                }
                aria-expanded={isExpanded}
                aria-controls={`collapse${index}`}
              >
                <Text field={list[key]?.fields?.accordionHeading} />
              </button>
            </h2>
            <div
              id={`collapse${index}`}
              className={`accordion-collapse ${
                isExpanded ? "expanded" : "collapsed"
              }`}
              aria-labelledby={`heading${index}`}
            >
              {isExpanded && (
                <div className="accordion-body">
                  <RichText field={list[key]?.fields?.accordionDescription} />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const MyResourcesWellnessBenefitsandPrograms = (props) => {
  const [expandedIndex, setExpandedIndex] = useState(null); // State to track the expanded accordion item
  let fields = props?.fields;
  let { MemberStateName, memberLOB } = useContext(AppContext);

  let list = getStateConfiguration(fields, MemberStateName, memberLOB);

  const isMedicaidMember = memberLOB?.toLowerCase() === MEDICAID;
  const isMarketplaceMember = memberLOB?.toLowerCase() === MARKETPLACE;
  const isCAMedicaidMember =
    isMedicaidMember && MemberStateName?.toUpperCase() === CA;
  const isCAMarketplaceMember =
    isMarketplaceMember && MemberStateName?.toUpperCase() === CA;

  return isCAMedicaidMember || isCAMarketplaceMember ? (
    <div className="row">
      &nbsp;
      {displayAccordionItems(list, expandedIndex, setExpandedIndex)}
    </div>
  ) : (
    ""
  );
};

export default MyResourcesWellnessBenefitsandPrograms;
