import React, { useContext } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { AppContext } from "../../../../../contexts/app.context";
import { IMAGE_PATH, VA } from "../../../../../define.constants";

const ViewIDCardMedicare = ({ fields, result, details, idCard, drugInfo }) => {
  let { MemberStateName, planName } = useContext(AppContext);
  const formatToday = () => {
    let date = new Date();
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };
  console.log("result", result);
  console.log("icCard", idCard);
  let portalDeductiblesDetail =
    result?.PortalDeductiblesMappingDetails &&
    result?.PortalDeductiblesMappingDetails.length > 0
      ? result?.PortalDeductiblesMappingDetails[0]
      : null;
  // Pulling NAL English number
  const contactinfo = () => {
    return fields.lblbackNurseEnglishData;
    // switch (MemberStateName) {
    //   case VA:
    //     return fields.lblbackNurseEnglishData;
    //   default:
    //     return fields.lblbackNurseEnglishData;
    // }
  };

  // Pulling NAL Spanish number
  const contactinfoSpanish = () => {
    switch (MemberStateName) {
      case VA:
        return fields.lblbackNurseSpanishDataWA;
      default:
        return fields.lblbackNurseEnglishData;
    }
  };

  return (
    <>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 id-card-new">
        <p className="text-align-center">
          <Text field={fields.lblFront} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NonMP/VA/Medicare/VAMbrIDCard_front.png)`,
          }}
          className="background-idcard background-id-row"
        >
          <div>
            <span className="plan-name-id-card"></span>
          </div>
          <br></br>
          <table
            className="medicare-table"
            width="100%"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            id="memberCurrentID"
          >
            <tbody>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblMember} />
                  </b>
                  &nbsp; {idCard?.Member_Name}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblmemberno} />
                  </b>
                  &nbsp; {idCard?.OriginalMemberProfile?.Number}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblMedicaidNumber} />
                  </b>
                  &nbsp; {idCard?.MedicaidId}
                </td>
              </tr>
              <tr>
                <td className="height-adjustment-pcp"></td>
              </tr>
              <tr>
                <td className="td-width"></td>
                <td>
                  &nbsp;&nbsp;
                  <b>
                    <Text field={fields.lblBin} />
                  </b>
                  &nbsp;
                  {portalDeductiblesDetail?.RxBIN}
                  {/*<Text field={fields.lblRxBinValue} /> */}
                </td>
              </tr>
              <tr>
                <td style={{ height: "3px" }}></td>
              </tr>
              <tr>
                <td className="td-width"></td>
                <td>
                  &nbsp;&nbsp;
                  <b>
                    <Text field={fields.lblPCN} />
                  </b>
                  &nbsp; {portalDeductiblesDetail?.RxPCN}
                  {/* <Text field={fields.lblPCNValue} /> */}
                </td>
              </tr>
              <tr>
                <td style={{ height: "3px" }}></td>
              </tr>
              <tr>
                <td className="td-width"></td>
                <td>
                  &nbsp;&nbsp;
                  <b>
                    <Text field={fields.lblGroup} />
                  </b>
                  &nbsp;{portalDeductiblesDetail?.RxGroup}
                </td>
              </tr>
              <tr>
                <td style={{ height: "3px" }}></td>
              </tr>
              <tr>
                <td className="td-width"></td>
                <td>
                  &nbsp;&nbsp;
                  <b>
                    <Text field={fields.lblRxid} />
                  </b>
                  &nbsp; {idCard?.OriginalMemberProfile?.Number}
                </td>
              </tr>
              <tr className="medicare-id-card-spacer">
                <td style={{ height: "49px" }}></td>
              </tr>
              <tr>
                <td></td>
                <td style={{ fontSize: "14px" }}>{}</td>
              </tr>
              <tr>
                <td style={{ height: "12px" }}></td>
              </tr>
              <tr>
                <td className="td-width"></td>
                <td>
                  &nbsp;&nbsp;
                  {result?.PortalDeductiblesMappingDetails[0]?.ContractPlanId}
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <Text field={fields.lblIssueDate} />
                  </b>
                  &nbsp;{formatToday()}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 id-card-new">
        <p className="text-align-center">
          <Text field={fields.lblBack} />
        </p>
        <div
          style={{
            backgroundImage: `url(${IMAGE_PATH}/id/NONMP/WI/Medicare/Medicare_ID_Card_Molina_BLANK_220914_r1_FNL2.png)`,
          }}
          className="member-id-card-back"
        >
          <table
            width="100%"
            border="0"
            cellPadding="2"
            cellSpacing="0"
            id="memberCurrentID"
          >
            <tbody>
              <tr>
                <td>
                  <div className="TXbackwidth">
                    <ul className="list-unstyled TXmrgn">
                      <li>
                        <b>
                          <Text field={fields.lblIDBackHeading} />
                        </b>
                      </li>
                      <li>
                        <b>
                          <Text field={fields.lblIDBackServices} />
                        </b>
                        &nbsp;
                        <Text field={fields?.lblMemberServicesMedcareValueVA} />
                      </li>
                      <li>
                        <b>
                          <Text field={fields.lblIDBackNAL} />
                        </b>
                        {contactinfo().value}
                      </li>
                      <br></br>
                      {/* <li>
                        <Text field={fields.lblIDBackNALSpanish} />
                        {contactinfoSpanish().value}
                      </li> */}
                      <li>
                        <b>
                          <Text field={fields.lblIDBackProvider} />
                        </b>
                        <Text field={fields.lblIDBackProviderData} />
                      </li>
                      <br></br>
                      <li>
                        <b>
                          <Text field={fields.lblbackSendClaims} />
                        </b>
                      </li>
                      <li>
                        <b>
                          <Text field={fields.lblbackMedicalHospital} />
                        </b>
                      </li>
                      <li>
                        <Text field={fields.lblbackmedicalData} />
                      </li>
                      <li>
                        <b>
                          <Text field={fields.lblbackPharmacy} />
                        </b>
                      </li>
                      <li>
                        <Text field={fields.lblbackPharmacyData} />
                      </li>
                      <li>
                        <b>
                          <Text field={fields.lblbackGeneralMailingAddress} />
                        </b>
                      </li>
                      <li>
                        <Text field={fields.lblbackGeneralMailingAddressData} />
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ViewIDCardMedicare;
