import React, { useState, useContext, useEffect } from "react";

import { SpinnerContext } from "../../contexts/spinner.context";
import * as MemberAPI from "../../services/MemberAPI";
import {
  SPANISH_LANG_CODE,
  CHINESE_LANG_CODE,
  ENGLISH_LANG_CODE,
  SPANISH_LANG_TEXT,
  CHINESE_LANG_TEXT,
  HomePageUrl,
  POA_PR_RESULT_CODE,
  LoginPageUrl,
  CHINESE_LANG_PATH,
  SPANISH_LANG_PATH,
  ENGLISH_LANG_PATH,
} from "../../define.constants";
import { withModalProvider } from "../../hoc/withModalProvider";
import { SSORedirectProvider } from "../../contexts/SSORedirect.context";
import SapphireRedirect from "./SapphireRedirect";

const CommonMyMolinaLogin = (props) => {
  const { setSpinner } = useContext(SpinnerContext);

  const [authCode, setAuthCode] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("error") == "server_error") {
      location = LoginPageUrl + "#defaulterror";
    }
    setAuthCode(queryParams.get("code"));
    const state = queryParams.get("state");
    localStorage.setItem("state", state);
  }, []);

  const landingPageUrls = {
    rxsspage: "/RxSavingsSolutions",
    mydoctor: "/MyDoctor",
    memberidcard: "/MemberIDCard",
    healthrecord: "/HealthRecord",
  };
  const [sapphireRedirect, setSapphireRedirect] = useState(false);

  useEffect(() => {
    if (authCode) {
      setSpinner(true);
      MemberAPI.getuserLogin({
        AuthCode: authCode,
        RedirectUri: window.location.origin + window.location.pathname,
      })
        .then((data) => {
          if (data && data.ResultCode === POA_PR_RESULT_CODE) {
            location = "/#PRActive";
          } else if (data && data.ResultData) {
            data = data.ResultData;
            localStorage.setItem("loginData", JSON.stringify(data));
            const landingPage = localStorage.getItem("landingPage");
            const messageQuery = localStorage.getItem("messageQuery");
            const channel = localStorage.getItem("channel");
            if (
              landingPage !== null &&
              landingPage !== "" &&
              landingPageUrls[landingPage.toLocaleLowerCase()]
            ) {
              if (
                data.Language !== null &&
                data.Language !== "" &&
                (data.Language.toLocaleLowerCase() ===
                  SPANISH_LANG_CODE.toLocaleLowerCase() ||
                  data.Language.toLowerCase() ===
                    SPANISH_LANG_TEXT.toLowerCase())
              ) {
                location =
                  SPANISH_LANG_PATH +
                  landingPageUrls[landingPage.toLocaleLowerCase()];
              } else if (
                data.Language !== null &&
                data.Language !== "" &&
                (data.Language.toLocaleLowerCase() ===
                  CHINESE_LANG_CODE.toLocaleLowerCase() ||
                  data.Language.toLowerCase() ===
                    CHINESE_LANG_TEXT.toLowerCase())
              ) {
                location =
                  CHINESE_LANG_PATH +
                  landingPageUrls[landingPage.toLocaleLowerCase()];
              } else {
                location =
                  ENGLISH_LANG_PATH +
                  landingPageUrls[landingPage.toLocaleLowerCase()];
              }
            } else if (channel !== null && channel !== "" && channel === "sd") {
              setSapphireRedirect(true);
            }
            //condition for mc=MessageID
            else {
              location =
                "/" +
                (data.Language !== "" &&
                (SPANISH_LANG_TEXT.toLowerCase() ===
                  data.Language.toLowerCase() ||
                  SPANISH_LANG_CODE.toLocaleLowerCase() ===
                    data.Language.toLocaleLowerCase())
                  ? SPANISH_LANG_CODE
                  : CHINESE_LANG_TEXT.toLowerCase() ===
                      data.Language.toLowerCase() ||
                    CHINESE_LANG_CODE.toLocaleLowerCase() ===
                      data.Language.toLocaleLowerCase()
                  ? CHINESE_LANG_CODE
                  : ENGLISH_LANG_CODE) +
                (messageQuery
                  ? messageQuery === "msg"
                    ? "/MyMessages"
                    : `/MyMessages?SFMessageID=${messageQuery}`
                  : HomePageUrl);
            }
          } else {
            location = "/#loginfailed";
          }
        })
        .catch((err) => {
          location = "/#defaulterror";
        })
        .finally(() => {
          setSpinner(false);
        });
    }
  }, [authCode]);

  if (sapphireRedirect)
    return (
      <SSORedirectProvider>
        <SapphireRedirect></SapphireRedirect>
      </SSORedirectProvider>
    );
  else return <></>;
};

export default withModalProvider(CommonMyMolinaLogin);
