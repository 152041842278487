import React, { useContext } from "react";
import { Image, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
import { AppContext } from "../../contexts/app.context";
import {
  MEDICARE,
  DUALS,
  MEDICAID,
  UT_PROGRAMID_26,
  NY,
  KY,
  MA,
  TX,
  TX_DUALS_49,
  FL,
  MS,
  WA,
  VA,
  ICON_PATH,
  FL_SP_PROGRAMID,
  FL_Medicaid_Planid,
  FL_MMA_LTC_PROGRAMID,
  FL_Medicaid_planid1,
  FL_Medicaid_planid2,
} from "../../define.constants";
const NonMarketplaceDocument = ({
  DocOrLinkUnavailability,
  distItem,
  fields,
  comingSoonKey,
  TOGGLE_YEAR,
}) => {
  const {
    index,
    MemberStateName,
    memberLOB,
    planId,
    programId,
    isNYAffinityMember,
    isCABrightcareMember,
    enrollmentEffDate,
  } = useContext(AppContext);
  const keys = distItem !== null ? Object.keys(distItem?.phrases) : null;
  let param = "";
  if (memberLOB.toLocaleLowerCase() == MEDICARE.toLocaleLowerCase()) {
    param =
      MemberStateName +
      `~` +
      memberLOB +
      `~` +
      fields?.docName?.value +
      `~` +
      programId +
      `~` +
      planId +
      `~`;
  } else if (memberLOB.toLocaleLowerCase() == DUALS.toLocaleLowerCase()) {
    param =
      MemberStateName +
      `~` +
      memberLOB +
      `~` +
      fields?.docName?.value +
      `~` +
      programId +
      `~`;
  } else if (memberLOB.toLocaleLowerCase() == MEDICAID.toLocaleLowerCase()) {
    param =
      MemberStateName +
      `~` +
      memberLOB +
      `~` +
      fields?.docName?.value +
      `~` +
      programId +
      `~` +
      planId +
      `~`;
  }

  if (memberLOB.toLocaleLowerCase() == MEDICAID.toLocaleLowerCase()) {
    if (
      programId == UT_PROGRAMID_26 ||
      MemberStateName == TX ||
      MemberStateName == FL ||
      MemberStateName == MS ||
      MemberStateName == WA
    ) {
      param =
        MemberStateName +
        `~` +
        memberLOB +
        `~` +
        fields?.docName?.value +
        `~` +
        programId +
        `~`;
    }
  }

  if (memberLOB.toLocaleLowerCase() == MEDICAID.toLocaleLowerCase()) {
    if (MemberStateName == NY) {
      param =
        MemberStateName +
        `~` +
        memberLOB +
        `~` +
        fields?.docName?.value +
        `~` +
        programId +
        `~` +
        isNYAffinityMember +
        `~`;
    }
  }
  if (memberLOB.toLocaleLowerCase() == DUALS.toLocaleLowerCase()) {
    if (programId == TX_DUALS_49 || MemberStateName == TX) {
      param =
        MemberStateName +
        `~` +
        memberLOB +
        `~` +
        fields?.docName?.value +
        `~` +
        programId +
        `~` +
        planId +
        `~`;
    }
  }
  //FL HomePage Helpful  link implementation
  if (memberLOB.toLocaleLowerCase() == MEDICAID.toLocaleLowerCase()) {
    if (
      ((programId == FL_SP_PROGRAMID && planId == FL_Medicaid_Planid) ||
        (programId == FL_MMA_LTC_PROGRAMID &&
          (planId == FL_Medicaid_planid1 || planId == FL_Medicaid_planid2))) &&
      MemberStateName == FL
    ) {
      param =
        MemberStateName +
        `~` +
        memberLOB +
        `~` +
        fields?.docName?.value +
        `~` +
        programId +
        `~` +
        planId +
        `~`;
    }
  }

  let currentYear = new Date().getFullYear();
  let selectedYear;

  if (isCABrightcareMember) {
    selectedYear = new Date(enrollmentEffDate).getFullYear();
  } else {
    selectedYear = currentYear < TOGGLE_YEAR ? TOGGLE_YEAR : currentYear;
  }

  let param1 = MemberStateName + `~` + memberLOB + `~` + fields?.docName?.value;
  let planurl = "#";
  if (keys !== null && keys !== undefined) {
    keys.forEach((item) => {
      if (
        (selectedYear &&
          (item
            .toLocaleLowerCase()
            .includes((param + selectedYear).toLocaleLowerCase()) ||
            (item
              .toLocaleLowerCase()
              .includes((param1 + "~" + selectedYear).toLocaleLowerCase()) &&
              fields?.docName?.value === "Handbook"))) ||
        ((selectedYear == null || selectedYear == "") &&
          (item.toLocaleLowerCase().includes(param.toLocaleLowerCase()) ||
            (item.toLocaleLowerCase().includes(param1.toLocaleLowerCase()) &&
              fields?.docName?.value === "Handbook")) &&
          item.includes("Common"))
      ) {
        planurl = distItem?.phrases[item];
        // for pdfs
        if (fields?.fromFile?.value) {
          planurl = ICON_PATH + "/" + planurl;
        }
        return planurl;
      }
      // return planurl;
    });
    // return planurl;
  }
  if (selectedYear == distItem?.phrases?.comingSoonYear && planurl === "#") {
    planurl = distItem?.phrases[comingSoonKey];
  }
  if (
    fields?.excludePlanIds?.value === "" ||
    (fields?.excludePlanIds?.value !== "" &&
      fields?.excludePlanIds?.value.toString().split(",").indexOf(planId) < 0)
  ) {
    return (
      <div className="carousel-item-block" key={index}>
        <WhoIsAuthorized
          type="link"
          href={planurl}
          actionUnavailable={DocOrLinkUnavailability(planurl)}
        >
          {({ handleClick, href }) => {
            return (
              <a
                id={`a_downloadPlanIcon${index}`}
                href={href}
                onClick={handleClick}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="card ">
                  <Image
                    className="carousel-icon"
                    key={index}
                    field={fields?.image}
                  />
                  <div>
                    <div>{getFieldValue(fields, "title")}</div>
                  </div>
                </div>
              </a>
            );
          }}
        </WhoIsAuthorized>
      </div>
    );
  } else {
    return null;
  }
};

export default NonMarketplaceDocument;
