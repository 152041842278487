import React, { useContext, useState } from "react";
import { AppContext } from "../../contexts/app.context";
import {
  RichText,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import { InlineFormError } from "../../common/InlineFormError";
import { ErrorContext } from "../../contexts/error.context";
import * as MessageAPI from "../../services/MessagesAPI";
import { SpinnerContext } from "../../contexts/spinner.context";
import { ModalContext } from "./../../contexts/modal.context";
import {
  PHONE_DIGIT_ONLY_REGEX,
  SUCCESS_RESULT_CODE,
} from "../../define.constants";

const MedicareForm = ({ fields, MemberStateName }) => {
  const [component, setComponent] = useState({
    desc1: false,
    desc2: false,
    desc3: false,
  });
  let { prefLang, isCABrightcareMember } = useContext(AppContext);
  const requiredMark = <span style={{ color: "red" }}>*</span>;
  const { openModal, closeModal } = useContext(ModalContext);
  const { setSpinner } = useContext(SpinnerContext);
  const { setError } = useContext(ErrorContext);

  const handleCheckboxChange = (e, setFieldValue) => {
    const { name, checked } = e.target;
    setComponent((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
    setFieldValue(name, checked ? "Yes" : "No");
  };

  const handleReset = () => {
    setComponent({
      desc1: false,
      desc2: false,
      desc3: false,
      signature: "",
    });
    formValues.resetForm();
  };

  const PhoneFormat = (e, props) => {
    let curated = e.target.value?.replace(PHONE_DIGIT_ONLY_REGEX, "");
    if (curated.length > 10) {
      curated = curated.slice(0, 10);
    }
    if (curated.length > 6) {
      curated = curated.slice(0, 6) + "-" + curated.slice(6);
    }
    if (curated.length > 3) {
      curated = "(" + curated.slice(0, 3) + ")" + curated.slice(3);
    }
    if (props.values.phoneNumber != curated) {
      props.setFieldValue("phone", curated);
    }
  };

  const ZipFormat = (e, props) => {
    let curated = e.target.value?.replace(PHONE_DIGIT_ONLY_REGEX, "");
    if (curated.length > 9) {
      curated = curated.slice(0, 9);
    }
    if (curated.length > 5) {
      curated = curated.slice(0, 5) + "-" + curated.slice(5);
    }
    if (props.values.zipCode != curated) {
      props.setFieldValue("zip", curated);
    }
  };

  const RequestSchema = Yup.object().shape({
    firstName: Yup.string().required(
      getFieldValue(fields, "firstNameValidationMSG")
    ),
    lastName: Yup.string().required(
      getFieldValue(fields, "lastNameValidationMSG")
    ),
    middleInitial: Yup.string(),
    medicareNumber: Yup.string().required(
      getFieldValue(fields, "medicareNumberValidationMSG")
    ),
    birthDate: Yup.date().required(
      getFieldValue(fields, "birthDateValidationMSG")
    ),
    phoneNumber: Yup.string().required(
      getFieldValue(fields, "phoneNumberValidationMSG")
    ),
    address: Yup.string().required(
      getFieldValue(fields, "permAddressValidationMSG")
    ),
    city: Yup.string().required(getFieldValue(fields, "cityValidationMSG")),
    county: Yup.string(),
    state: Yup.string().required(getFieldValue(fields, "stateValidationMSG")),
    zipCode: Yup.string().required(
      getFieldValue(fields, "zipCodeValidationMSG")
    ),
    mailingAddressDisc: Yup.string(),
    mailingAddress: Yup.string(),
    mailingCity: Yup.string(),
    mailingState: Yup.string(),
    mailingZipCode: Yup.string(),
    signature: Yup.string().required(
      getFieldValue(fields, "signatureValidationMSG")
    ),
    date: Yup.string().required(getFieldValue(fields, "dateValidationMSG")),
    authorizedName: Yup.string(),
    authorizedAddress: Yup.string(),
    authorizedPhoneNumber: Yup.string(),
    relationship: Yup.string(),
  });

  const today = new Date().toISOString().split("T")[0];

  const formValues = {
    firstName: "",
    lastName: "",
    middleInitial: "",
    medicareNumber: "",
    birthDate: "",
    phoneNumber: "",
    address: "",
    city: "",
    county: "",
    state: "",
    zipCode: "",
    mailingAddressDisc: "",
    mailingAddress: "",
    mailingCity: "",
    mailingState: "",
    mailingZipCode: "",
    desc1: false,
    desc2: false,
    desc3: false,
    signature: "",
    date: "",
    authorizedName: "",
    authorizedAddress: "",
    authorizedPhoneNumber: "",
    relationship: "",
  };

  const successModal = (
    <>
      <div className="account-modal">
        <div className="update-header">
          <p>
            <Text field={fields?.lblSuccessMsg ?? ""} />
          </p>
        </div>
        <div className="comm-btns">
          <button
            type="button"
            className="btn continue-button"
            data-mdb-dismiss="modal"
            onClick={closeModal}
          >
            <Text field={fields?.lblClose ?? ""} />
          </button>
        </div>
      </div>
    </>
  );

  const successUpdate = () => {
    openModal({
      header: <div className="request-new-id-header"></div>,
      content: successModal,
      version: 1,
    });
  };

  const heading = (
    <h3
      className="tab-sub-title blue-text"
      style={{ paddingBottom: "0.76rem" }}
    >
      <Text field={fields.heading} />
    </h3>
  );

  const handleNumericInputChange = (e, setFieldValue, fieldName) => {
    const { value } = e.target;
    const numericValue = value.replace(/[^0-9]/g, "");
    setFieldValue(fieldName, numericValue);
  };

  const handleAlphabeticWithSpacesInputChange = (
    e,
    setFieldValue,
    fieldName
  ) => {
    const { value } = e.target;
    const alphabeticValue = value.replace(/[^a-zA-Z\s]/g, "");
    setFieldValue(fieldName, alphabeticValue);
  };

  const formatDateToMMDDYYYY = (date) => {
    const d = new Date(date);
    const utcDate = new Date(d.getTime() + d.getTimezoneOffset() * 60000);
    const month = ("0" + (utcDate.getMonth() + 1)).slice(-2);
    const day = ("0" + utcDate.getDate()).slice(-2);
    const year = utcDate.getFullYear();
    return `${month}/${day}/${year}`;
  };

  return (
    <Formik
      initialValues={formValues}
      onSubmit={(submitProps, { resetForm }) => {
        setSpinner(true);

        MessageAPI.SubmitMedicareForm({
          FName: submitProps.firstName,
          LName: submitProps.lastName,
          MiddleInitial: submitProps.middleInitial,
          MedicareNumber: submitProps.medicareNumber,
          BirthDate: formatDateToMMDDYYYY(submitProps.birthDate),
          PhoneNumber: submitProps.phoneNumber,
          StreetAddress: submitProps.address,
          City: submitProps.city,
          County: submitProps.county,
          State: submitProps.state,
          ZipCode: submitProps.zipCode,
          MailingAddressDesc: submitProps.mailingAddressDisc,
          MailingAddress: submitProps.mailingAddress,
          MailingCity: submitProps.mailingCity,
          MailingState: submitProps.mailingState,
          MailingZipCode: submitProps.mailingZipCode,
          CheckBox1: submitProps.desc1,
          CheckBox2: submitProps.desc2,
          CheckBox3: submitProps.desc3,
          Signature: submitProps.signature,
          Date: formatDateToMMDDYYYY(submitProps.date),
          AuthorizedName: submitProps.authorizedName,
          AuthorizedAddress: submitProps.authorizedAddress,
          AuthorizedPhoneNumber: submitProps.authorizedPhoneNumber,
          Relationship: submitProps.relationship,
          Language: prefLang,
        })
          .then((response) => {
            setSpinner(false);
            if (response?.ResultCode === SUCCESS_RESULT_CODE) {
              successUpdate();
              resetForm();
              setComponent((prevState) => ({
                ...prevState,
                desc1: false,
                desc2: false,
                desc3: false,
              }));
            }
          })
          .catch((err) => {
            setSpinner(false);
            setError({ err });
          });
      }}
      validationSchema={RequestSchema}
      enableReinitialize
    >
      {(props) => {
        return (
          <Form>
            <br></br>
            <br></br>
            <div className="row m-2">{heading}</div>
            <hr />
            <div className="row m-2">
              <div
                className="col-sm-12 col-md-1 row-title align-self-center"
                style={{ marginRight: "100px" }}
              >
                <label htmlFor="firstName" style={{ display: "ruby-text" }}>
                  {requiredMark}
                  <Text field={fields.firstName} />
                </label>
              </div>
              <div className="col-sm-12 col-md-3 align-self-center row-title">
                <Field
                  name="firstName"
                  id="firstName"
                  type="text"
                  className={`form-control ${
                    props.errors.firstName &&
                    props.touched.firstName &&
                    "is-invalid"
                  }`}
                  style={{ display: "block" }}
                  onChange={(e) =>
                    handleAlphabeticWithSpacesInputChange(
                      e,
                      props.setFieldValue,
                      "firstName"
                    )
                  }
                />
                <ErrorMessage name="firstName" component={InlineFormError} />
              </div>
              <div
                className="col-sm-12 col-md-1 row-title align-self-center"
                style={{ marginRight: "100px" }}
              >
                <label
                  htmlFor="medicareNumber"
                  style={{ display: "ruby-text" }}
                >
                  {requiredMark}
                  <Text field={fields.medicareNumber} />
                </label>
              </div>
              <div className="col-sm-12 col-md-3 align-self-center row-title">
                <Field
                  name="medicareNumber"
                  id="medicareNumber"
                  type="text"
                  className={`form-control ${
                    props.errors.medicareNumber &&
                    props.touched.medicareNumber &&
                    "is-invalid"
                  }`}
                  style={{ display: "block" }}
                />
                <ErrorMessage
                  name="medicareNumber"
                  component={InlineFormError}
                />
              </div>
            </div>
            <div className="row m-2">
              <div
                className="col-sm-12 col-md-1 row-title align-self-center"
                style={{ marginRight: "100px" }}
              >
                <label htmlFor="lastName" style={{ display: "ruby-text" }}>
                  {requiredMark}
                  <Text field={fields.lastName} />
                </label>
              </div>
              <div className="col-sm-12 col-md-3 align-self-center row-title">
                <Field
                  name="lastName"
                  id="lastName"
                  type="text"
                  className={`form-control ${
                    props.errors.lastName &&
                    props.touched.lastName &&
                    "is-invalid"
                  }`}
                  style={{ display: "block" }}
                  onChange={(e) =>
                    handleAlphabeticWithSpacesInputChange(
                      e,
                      props.setFieldValue,
                      "lastName"
                    )
                  }
                />
                <ErrorMessage name="lastName" component={InlineFormError} />
              </div>
              <div
                className="col-sm-12 col-md-1 row-title align-self-center"
                style={{ marginRight: "100px" }}
              >
                <label htmlFor="birthDate" style={{ display: "ruby-text" }}>
                  {requiredMark}
                  <Text field={fields.birthDate} />
                </label>
              </div>
              <div className="col-sm-12 col-md-3 align-self-center row-title">
                <Field
                  name="birthDate"
                  id="birthDate"
                  type="date"
                  max={today}
                  className={`form-control ${
                    props.errors.birthDate &&
                    props.touched.birthDate &&
                    "is-invalid"
                  }`}
                  style={{ display: "block" }}
                />
                <ErrorMessage name="birthDate" component={InlineFormError} />
              </div>
            </div>
            <div className="row m-2">
              <div
                className="col-sm-12 col-md-3 row-title align-self-center"
                style={{ marginRight: "-77px" }}
              >
                <label htmlFor="middleInitial">
                  <Text field={fields.middleInitial} />
                </label>
              </div>
              <div className="col-sm-12 col-md-3 align-self-center row-title">
                <Field
                  name="middleInitial"
                  id="middleInitial"
                  type="text"
                  className="form-control"
                  style={{ display: "block" }}
                  onChange={(e) =>
                    handleAlphabeticWithSpacesInputChange(
                      e,
                      props.setFieldValue,
                      "middleInitial"
                    )
                  }
                />
              </div>
              <div
                className="col-sm-12 col-md-1 row-title align-self-center"
                style={{ marginRight: "100px" }}
              >
                <label htmlFor="phoneNumber" style={{ display: "ruby-text" }}>
                  {requiredMark}
                  <Text field={fields.phoneNumber} />
                </label>
              </div>
              <div className="col-sm-12 col-md-3 align-self-center row-title">
                <Field
                  name="phoneNumber"
                  id="phoneNumber"
                  type="text"
                  maxLength="13"
                  onKeyDown={(e) => PhoneFormat(e, props)}
                  onChange={(e) =>
                    handleNumericInputChange(
                      e,
                      props.setFieldValue,
                      "phoneNumber"
                    )
                  }
                  className={`form-control ${
                    props.errors.phoneNumber &&
                    props.touched.phoneNumber &&
                    "is-invalid"
                  }`}
                />
                <ErrorMessage name="phoneNumber" component={InlineFormError} />
              </div>
            </div>

            <div className="row m-2">
              <div className="col-sm-12 col-md-5 row-title align-self-center">
                <label htmlFor="address">
                  {requiredMark}
                  <Text field={fields.permaddress} />
                </label>
              </div>
              <div className="col-sm-12 col-md-5 align-self-center row-title">
                <Field
                  name="address"
                  id="address"
                  type="text"
                  className={`form-control ${
                    props.errors.address &&
                    props.touched.address &&
                    "is-invalid"
                  }`}
                />
                <ErrorMessage name="address" component={InlineFormError} />
              </div>
            </div>
            <div className="row m-2">
              <div className="col-sm-12 col-md-1 row-title align-self-center">
                <label htmlFor="city">
                  {requiredMark}
                  <Text field={fields.city} />
                </label>
              </div>
              <div className="col-sm-12 col-md-3 align-self-center row-title">
                <Field
                  name="city"
                  id="city"
                  type="text"
                  className={`form-control ${
                    props.errors.city && props.touched.city && "is-invalid"
                  }`}
                  onChange={(e) =>
                    handleAlphabeticWithSpacesInputChange(
                      e,
                      props.setFieldValue,
                      "city"
                    )
                  }
                />
                <ErrorMessage name="city" component={InlineFormError} />
              </div>
              <div className="col-sm-12 col-md-1 row-title align-self-center">
                <label htmlFor="county">
                  <Text field={fields.county} />
                </label>
              </div>
              <div className="col-sm-12 col-md-3 align-self-center row-title">
                <Field
                  name="county"
                  id="county"
                  type="text"
                  className={`form-control ${
                    props.errors.county && props.touched.county && "is-invalid"
                  }`}
                  onChange={(e) =>
                    handleAlphabeticWithSpacesInputChange(
                      e,
                      props.setFieldValue,
                      "county"
                    )
                  }
                />
                <ErrorMessage name="county" component={InlineFormError} />
              </div>
            </div>
            <div className="row m-2">
              <div className="col-sm-12 col-md-1 row-title align-self-center">
                <label htmlFor="state">
                  {requiredMark}
                  <Text field={fields.state} />
                </label>
              </div>
              <div className="col-sm-12 col-md-3 align-self-center row-title">
                <Field
                  name="state"
                  id="state"
                  type="text"
                  className={`form-control ${
                    props.errors.state && props.touched.state && "is-invalid"
                  }`}
                  onChange={(e) =>
                    handleAlphabeticWithSpacesInputChange(
                      e,
                      props.setFieldValue,
                      "state"
                    )
                  }
                />
                <ErrorMessage name="state" component={InlineFormError} />
                {props.values.state === "" &&
                  MemberStateName &&
                  props.setFieldValue("state", MemberStateName)}
              </div>
              <div className="col-sm-12 col-md-1 row-title align-self-center">
                <label htmlFor="zipCode">
                  {requiredMark}
                  <Text field={fields.zipCode} />
                </label>
              </div>
              <div className="col-sm-12 col-md-3 align-self-center row-title">
                <Field
                  name="zipCode"
                  id="zipCode"
                  type="text"
                  maxLength="10"
                  onKeyDown={(e) => ZipFormat(e, props)}
                  onChange={(e) =>
                    handleNumericInputChange(e, props.setFieldValue, "zipCode")
                  }
                  className={`form-control ${
                    props.errors.zipCode &&
                    props.touched.zipCode &&
                    "is-invalid"
                  }`}
                />
                <ErrorMessage name="zipCode" component={InlineFormError} />
              </div>
            </div>
            <div className="row m-2">
              <div className="col-sm-12 col-md-5 row-title align-self-center">
                <label htmlFor="mailingAddressDisc">
                  <Text field={fields.mailingAddressDisc} />
                </label>
              </div>
              <div className="col-sm-12 col-md-5 align-self-center row-title">
                <Field
                  name="mailingAddressDisc"
                  id="mailingAddressDisc"
                  type="text"
                  className={`form-control ${
                    props.errors.mailingAddressDisc &&
                    props.touched.mailingAddressDisc &&
                    "is-invalid"
                  }`}
                />
                <ErrorMessage
                  name="mailingAddress"
                  component={InlineFormError}
                />
              </div>
            </div>
            <div className="row m-2">
              <div className="col-sm-12 col-md-1 row-title align-self-center">
                <label htmlFor="mailingAddress">
                  <Text field={fields.mailingAddress} />
                </label>
              </div>
              <div className="col-sm-12 col-md-3 align-self-center row-title">
                <Field
                  name="mailingAddress"
                  id="mailingAddress"
                  type="text"
                  className={`form-control ${
                    props.errors.mailingAddress &&
                    props.touched.mailingAddress &&
                    "is-invalid"
                  }`}
                />
                <ErrorMessage
                  name="mailingAddress"
                  component={InlineFormError}
                />
              </div>
              <div className="col-sm-12 col-md-1 row-title align-self-center">
                <label htmlFor="mailingCity">
                  <Text field={fields.mailingCity} />
                </label>
              </div>
              <div className="col-sm-12 col-md-3 align-self-center row-title">
                <Field
                  name="mailingCity"
                  id="mailingCity"
                  type="text"
                  className={`form-control ${
                    props.errors.mailingCity &&
                    props.touched.mailingCity &&
                    "is-invalid"
                  }`}
                  onChange={(e) =>
                    handleAlphabeticWithSpacesInputChange(
                      e,
                      props.setFieldValue,
                      "mailingCity"
                    )
                  }
                />
                <ErrorMessage name="mailingCity" component={InlineFormError} />
              </div>
            </div>
            <div className="row m-2">
              <div className="col-sm-12 col-md-1 row-title align-self-center">
                <label htmlFor="mailingState">
                  <Text field={fields.mailingState} />
                </label>
              </div>
              <div className="col-sm-12 col-md-3 align-self-center row-title">
                <Field
                  name="mailingState"
                  id="mailingState"
                  type="text"
                  className={`form-control ${
                    props.errors.mailingState &&
                    props.touched.mailingState &&
                    "is-invalid"
                  }`}
                  onChange={(e) =>
                    handleAlphabeticWithSpacesInputChange(
                      e,
                      props.setFieldValue,
                      "mailingState"
                    )
                  }
                />
                <ErrorMessage name="mailingState" component={InlineFormError} />
              </div>
              <div className="col-sm-12 col-md-1 row-title align-self-center">
                <label htmlFor="mailingZipCode">
                  <Text field={fields.mailingZipCode} />
                </label>
              </div>
              <div className="col-sm-12 col-md-3 align-self-center row-title">
                <Field
                  name="mailingZipCode"
                  id="mailingZipCode"
                  type="text"
                  maxLength="10"
                  onKeyDown={(e) => ZipFormat(e, props)}
                  className={`form-control ${
                    props.errors.mailingZipCode &&
                    props.touched.mailingZipCode &&
                    "is-invalid"
                  }`}
                  onChange={(e) =>
                    handleNumericInputChange(
                      e,
                      props.setFieldValue,
                      "mailingZipCode"
                    )
                  }
                />
                <ErrorMessage
                  name="mailingZipCode"
                  component={InlineFormError}
                />
              </div>
            </div>
            <br></br>
            <hr />
            <div className="row m-2" style={{ display: "flex" }}>
              <div
                className="row-title"
                style={{
                  fontWeight: "bold",
                  fontSize: "inherit",
                }}
              >
                <label htmlFor="headingReadandSing">
                  <Text field={fields.headingReadandSing} />
                </label>
              </div>
            </div>
            {isCABrightcareMember ? (
              <div className="row m-2" style={{ display: "flex" }}>
                <div className="col-sm-12 col-md-1 row-title">
                  <Field
                    type="checkbox"
                    id="desc1"
                    name="desc1"
                    checked={component.desc1}
                    onChange={(e) =>
                      handleCheckboxChange(e, props.setFieldValue)
                    }
                    className={`form-check-input mr-2  ${
                      props.errors.desc1 && props.touched.desc1 && "is-invalid"
                    }`}
                  />
                </div>
                <div className="col-sm-12 col-md-9 row-title">
                  <label htmlFor="desc1">
                    <Text field={fields.desc1CHP} />
                  </label>
                </div>
              </div>
            ) : (
              <div className="row m-2" style={{ display: "flex" }}>
                <div className="col-sm-12 col-md-1 row-title">
                  <Field
                    type="checkbox"
                    id="desc1"
                    name="desc1"
                    checked={component.desc1}
                    onChange={(e) =>
                      handleCheckboxChange(e, props.setFieldValue)
                    }
                    className={`form-check-input mr-2  ${
                      props.errors.desc1 && props.touched.desc1 && "is-invalid"
                    }`}
                  />
                </div>
                <div className="col-sm-12 col-md-9 row-title">
                  <label htmlFor="desc1">
                    <Text field={fields.desc1} />
                  </label>
                </div>
              </div>
            )}
            {isCABrightcareMember ? (
              <div className="row m-2" style={{ display: "flex" }}>
                <div className="col-sm-12 col-md-1 row-title">
                  <Field
                    type="checkbox"
                    id="desc2"
                    name="desc2"
                    checked={component.desc2}
                    onChange={(e) =>
                      handleCheckboxChange(e, props.setFieldValue)
                    }
                    className={`form-check-input mr-2  ${
                      props.errors.desc2 && props.touched.desc2 && "is-invalid"
                    }`}
                  />
                </div>
                <div className="col-sm-12 col-md-9 row-title">
                  <label htmlFor="desc2">
                    <RichText field={fields.desc2CHP} />
                  </label>
                </div>
              </div>
            ) : (
              <div className="row m-2" style={{ display: "flex" }}>
                <div className="col-sm-12 col-md-1 row-title">
                  <Field
                    type="checkbox"
                    id="desc2"
                    name="desc2"
                    checked={component.desc2}
                    onChange={(e) =>
                      handleCheckboxChange(e, props.setFieldValue)
                    }
                    className={`form-check-input mr-2  ${
                      props.errors.desc2 && props.touched.desc2 && "is-invalid"
                    }`}
                  />
                </div>
                <div className="col-sm-12 col-md-9 row-title">
                  <label htmlFor="desc2">
                    <RichText field={fields.desc2} />
                  </label>
                </div>
              </div>
            )}
            {isCABrightcareMember ? (
              <div className="row m-2" style={{ display: "flex" }}>
                <div className="col-sm-12 col-md-1 row-title">
                  <Field
                    type="checkbox"
                    id="desc3"
                    name="desc3"
                    checked={component.desc3}
                    onChange={(e) =>
                      handleCheckboxChange(e, props.setFieldValue)
                    }
                    className={`form-check-input mr-2  ${
                      props.errors.desc3 && props.touched.desc3 && "is-invalid"
                    }`}
                  />
                </div>
                <div className="col-sm-12 col-md-9 row-title">
                  <label htmlFor="desc3">
                    <Text field={fields.desc3CHP} />
                  </label>
                </div>
              </div>
            ) : (
              <div className="row m-2" style={{ display: "flex" }}>
                <div className="col-sm-12 col-md-1 row-title">
                  <Field
                    type="checkbox"
                    id="desc3"
                    name="desc3"
                    checked={component.desc3}
                    onChange={(e) =>
                      handleCheckboxChange(e, props.setFieldValue)
                    }
                    className={`form-check-input mr-2  ${
                      props.errors.desc3 && props.touched.desc3 && "is-invalid"
                    }`}
                  />
                </div>
                <div className="col-sm-12 col-md-9 row-title">
                  <label htmlFor="desc3">
                    <Text field={fields.desc3} />
                  </label>
                </div>
              </div>
            )}
            <div className="row m-2">
              <div className="col-sm-12 col-md-1 row-title align-self-center">
                <label htmlFor="signature">
                  {requiredMark}
                  <Text field={fields.signature} />
                </label>
              </div>
              <div className="col-sm-12 col-md-3 align-self-center row-title">
                <Field
                  name="signature"
                  id="signature"
                  type="text"
                  placeholder={getFieldValue(fields, "lblSignature")}
                  className={`form-control ${
                    props.errors.signature &&
                    props.touched.signature &&
                    "is-invalid"
                  }`}
                  onChange={(e) =>
                    handleAlphabeticWithSpacesInputChange(
                      e,
                      props.setFieldValue,
                      "signature"
                    )
                  }
                />
                <ErrorMessage name="signature" component={InlineFormError} />
              </div>

              <div className="col-sm-12 col-md-1 row-title align-self-center">
                <label htmlFor="date">
                  {requiredMark}
                  <Text field={fields.date} />
                </label>
              </div>
              <div className="col-sm-12 col-md-3 align-self-center row-title">
                <Field
                  name="date"
                  id="date"
                  type="date"
                  max={today}
                  className={`form-control ${
                    props.errors.date && props.touched.date && "is-invalid"
                  }`}
                />
                <ErrorMessage name="date" component={InlineFormError} />
              </div>
            </div>
            <br></br>
            <hr />
            <br></br>
            <div className="row m-2">
              <div className="col-sm-12 col-md-10 row-title align-self-center">
                <label htmlFor="desc4">
                  <Text field={fields.desc4} />
                </label>
              </div>
            </div>
            <div className="row m-2">
              <div
                className="col-sm-12 col-md-1 row-title align-self-center"
                style={{ marginRight: "60px" }}
              >
                <label
                  htmlFor="authorizedName"
                  style={{ display: "ruby-text" }}
                >
                  <Text field={fields.authorizedName} />
                </label>
              </div>
              <div className="col-sm-12 col-md-3 align-self-center row-title">
                <Field
                  name="authorizedName"
                  id="authorizedName"
                  type="text"
                  className={`form-control ${
                    props.errors.authorizedName &&
                    props.touched.authorizedName &&
                    "is-invalid"
                  }`}
                  onChange={(e) =>
                    handleAlphabeticWithSpacesInputChange(
                      e,
                      props.setFieldValue,
                      "authorizedName"
                    )
                  }
                />
                <ErrorMessage
                  name="authorizedName"
                  component={InlineFormError}
                />
              </div>
              <div
                className="col-sm-12 col-md-3 row-title align-self-center"
                style={{ marginRight: "75px" }}
              >
                <label
                  htmlFor="authorizedAddress"
                  style={{ display: "ruby-text" }}
                >
                  <Text field={fields.authorizedAddress} />
                </label>
              </div>
              <div className="col-sm-12 col-md-3 align-self-center row-title">
                <Field
                  name="authorizedAddress"
                  id="authorizedAddress"
                  type="text"
                  className={`form-control ${
                    props.errors.authorizedAddress &&
                    props.touched.authorizedAddress &&
                    "is-invalid"
                  }`}
                />
                <ErrorMessage
                  name="authorizedAddress"
                  component={InlineFormError}
                />
              </div>
            </div>
            <div className="row m-2">
              <div
                className="col-sm-12 col-md-3 row-title align-self-center"
                style={{ marginRight: "-118px" }}
              >
                <label
                  htmlFor="authorizedPhoneNumber"
                  style={{ display: "ruby-text" }}
                >
                  <Text field={fields.authorizedPhoneNumber} />
                </label>
              </div>
              <div className="col-sm-12 col-md-3 align-self-center row-title">
                <Field
                  name="authorizedPhoneNumber"
                  id="authorizedPhoneNumber"
                  type="text"
                  maxLength="13"
                  onKeyDown={(e) => PhoneFormat(e, props)}
                  className={`form-control ${
                    props.errors.authorizedPhoneNumber &&
                    props.touched.authorizedPhoneNumber &&
                    "is-invalid"
                  }`}
                  onChange={(e) =>
                    handleNumericInputChange(
                      e,
                      props.setFieldValue,
                      "authorizedPhoneNumber"
                    )
                  }
                />
                <ErrorMessage
                  name="authorizedPhoneNumber"
                  component={InlineFormError}
                />
              </div>
              <div
                className="col-sm-12 col-md-1 row-title align-self-center"
                style={{ marginRight: "238px" }}
              >
                <label htmlFor="relationship" style={{ display: "ruby-text" }}>
                  <Text field={fields.relationship} />
                </label>
              </div>
              <div className="col-sm-12 col-md-3 align-self-center row-title">
                <Field
                  name="relationship"
                  id="relationship"
                  type="text"
                  className={`form-control ${
                    props.errors.relationship &&
                    props.touched.relationship &&
                    "is-invalid"
                  }`}
                  onChange={(e) =>
                    handleAlphabeticWithSpacesInputChange(
                      e,
                      props.setFieldValue,
                      "relationship"
                    )
                  }
                />
                <ErrorMessage name="relationship" component={InlineFormError} />
              </div>
            </div>
            <br></br>
            <hr />
            <br></br>
            <div>
              <button
                type="submit"
                className="btn btn-primary m-sm-3 my-md-3 ml-md-3 w-md-35 w-sm-100"
              >
                <Text field={fields.btnSubmit} />
              </button>
              <button
                type="reset"
                onClick={handleReset}
                className="btn btn-primary m-sm-3 my-md-3 ml-md-3 w-md-35 w-sm-100"
              >
                <Text field={fields.btnClear} />
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MedicareForm;
