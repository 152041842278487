import React, { useEffect, useState, useContext } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import DisplayItem from "../MyHealthRecord-Layout/Common/DisplayItem";
import DisplayTitleItem from "../MyHealthRecord-Layout/Common/DisplayTitleItem";
import RequestCareCoordination from "../RequestCareCoordination";
import {
  TX,
  TX_MD_PLUS_PROGRAMID,
  NM,
  NM_MEDICAID_ProgramID,
} from "../../define.constants";
import { AppContext } from "../../contexts/app.context";
import AssessmentValidationTX from "./AssessmentValidationTX";
import { ModalContext } from "../../contexts/modal.context";

const CareCoordinationTeam = (props) => {
  const { MemberStateName, programId } = useContext(AppContext);

  const { fields, data, data1, isSubmitAllowed } = props;
  let { dataToPassOnBtnClick } = "";
  if (
    data1 != null &&
    data1 != undefined &&
    data1.ResultData != null &&
    data1.ResultData != undefined
  ) {
    dataToPassOnBtnClick = data1?.ResultData?.RequestingEntity;
  }
  let isButtonClicked = false;
  const [isRequestBtnClicked, setData] = useState(false);
  const [requestingEntityToPass, setrequestingEntityToPass] = useState([]);
  const [cancelBtnClicked, setCancelBtnData] = useState(false);
  let [isCanceledUpdated, setCanceledUpdated] = useState(false);
  const { openModal } = useContext(ModalContext);

  //Added for new requirement
  const [showAssessment, setShowAssessment] = useState(false);
  useEffect(() => {
    setData(isButtonClicked);
  }, [isButtonClicked]);

  useEffect(() => {
    setrequestingEntityToPass(dataToPassOnBtnClick);
  }, [dataToPassOnBtnClick]);

  //TX Star Plus Program condition check
  const isTXStarPlusMember =
    MemberStateName == TX && programId == TX_MD_PLUS_PROGRAMID;
  //NM
  const isNMMedicaidMember =
    MemberStateName == NM && programId == NM_MEDICAID_ProgramID;
  // Popup message template
  const popupMessageTemplate = (heading, message) => (
    <div className="update-header">
      <div className="text-center">
        <p>{message}</p>
      </div>
    </div>
  );

  // Request Care Coordination Button Click function
  const CareTeamToRequestPage = () => {
    // For TX Star plus - Within 24 Hrs restriction
    if ((isTXStarPlusMember || !isNMMedicaidMember) && !isSubmitAllowed) {
      const successMsgTitle = fields?.successApiTitle?.value;
      const successMessage = fields?.successApiMessage?.value;
      const content = popupMessageTemplate(successMsgTitle, successMessage);

      // Open Pop-up modal to show message
      openModal({
        content,
        version: 1,
      });
    } else {
      isButtonClicked = true;
      setData(isButtonClicked);
      props.parentCallback(isButtonClicked);

      if (MemberStateName == TX || MemberStateName == NM) {
        setShowAssessment(true);
        //setSelectedAssessment(item);
      }
    }
  };
  //new requirement
  /** Back To List  */
  const backToMyWellness = () => {
    console.log("Back");
    setShowAssessment(false);
  };
  //cancelCallBack={handleCancelCallBack}. isCanceled property UPDATED value comes as TRUE from
  //RequestCareCordination Page when cancel is clicked
  const handleCancelCallBack = (isCanceled) => {
    isCanceledUpdated = isCanceled;
    setCanceledUpdated(isCanceledUpdated);
    props.cancelCallBackInRCC(isCanceledUpdated); //when cancel is clicked in request coordinatin page, call this listener which is available in index.js page
    setData(false);
  };

  useEffect(() => {
    setCanceledUpdated(isCanceledUpdated);
  }, [isCanceledUpdated]);

  return (
    <>
      {isRequestBtnClicked ? (
        (MemberStateName === "TX" || MemberStateName === "NM") &&
        showAssessment ? (
          <AssessmentValidationTX
            fields={props.fields}
            //backToMyWellness={backToMyWellness}
            assessment={{ id: "500511", version: "1" }}
            cancelCallBack={handleCancelCallBack}
          />
        ) : (
          <RequestCareCoordination
            requestingEntities={requestingEntityToPass}
            fields={props.fields}
            cancelCallBack={handleCancelCallBack}
          />
        )
      ) : (
        <>
          <div className="col-sm-12">
            {(MemberStateName != TX || MemberStateName != "NM") && (
              <div className="me-auto p-2">
                <h5 className="ohio-subheading">
                  <Text field={props.fields.MainTitle} />
                </h5>
              </div>
            )}
            {(MemberStateName != TX || MemberStateName != "NM") && (
              <DisplayItem
                nameField={fields.lblContactInfoHeading}
                outClass={"ohioText p-2"}
                titleClass={"paraText"}
                detailsClass={"paraText"}
              />
            )}
            <DisplayItem
              nameField={"Care Coordination Team - Molina HealthCare"}
              outClass={"col-md-6 col-xl-2"}
              titleClass={"list-desc-title"}
              detailsClass={"list-desc-details"}
            />
            <DisplayItem
              nameField={"Sub Heading"}
              outClass={"col-md-6 col-xl-2 d-xl-block d-none"}
              titleClass={"list-desc-title"}
              detailsClass={"list-desc-details"}
            />
          </div>
          <div className="d-flex mb-3">
            <div className="me-auto p-2">
              <h5 className="ohio-subheading">
                {(MemberStateName != TX || MemberStateName != NM) && (
                  <Text field={props.fields.Title} />
                )}
              </h5>
            </div>
            <div className="p-2">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => CareTeamToRequestPage()}
              >
                <Text field={fields.lblRequestBtn} />
              </button>
            </div>
          </div>
          {MemberStateName != TX &&
          !isNMMedicaidMember &&
          data != null &&
          data != undefined &&
          data.length == 0 ? (
            <p className="no-records">
              <Text field={props.fields.lblNoResults} />
            </p>
          ) : (
            <>
              {data?.map((item, index) => (
                <div className="careTeam" key={index}>
                  <div className="blockSec2 mb20" id="recordsToPrint">
                    <div className="row CareR1">
                      <DisplayItem
                        nameField={fields.lblCTProvFullName}
                        valueField={item.CareTeamproviderfullname}
                        outClass={"col-xxl-4 col-xl-4 col-md-6 col-sm-12"}
                        titleClass={"list-desc-title"}
                        detailsClass={"list-desc-details"}
                      />
                      <DisplayItem
                        nameField={fields.lblContactType}
                        valueField={item.ContactType}
                        outClass={"col-xxl-3 col-xl-3 col-md-6 col-sm-12"}
                        titleClass={"list-desc-title"}
                        detailsClass={"list-desc-details"}
                      />
                      <DisplayItem
                        nameField={fields.lblPhoneNumber}
                        valueField={item.PhoneNumber}
                        outClass={"col-xxl-2 col-xl-2 col-md-6 col-sm-12"}
                        titleClass={"list-desc-title"}
                        detailsClass={"list-desc-details"}
                      />
                      <DisplayItem
                        nameField={fields.lblAssigneDate}
                        valueField={item.AssignedDatestr}
                        outClass={"col-xxl-3 col-xl-3 col-md-6 col-sm-12"}
                        titleClass={"list-desc-title"}
                        detailsClass={"list-desc-details"}
                      />
                    </div>
                    <div className="row mt15">
                      <DisplayItem
                        nameField={fields.lblCareManagerName}
                        valueField={item.CareManagerFullName}
                        outClass={"col-xxl-4 col-xl-4 col-md-6 col-sm-12"}
                        titleClass={"list-desc-title"}
                        detailsClass={"list-desc-details"}
                      />
                      <DisplayItem
                        nameField={fields.lblCareManagerPhone}
                        valueField={item.Caremanagersphonenumber}
                        outClass={"col-xxl-3 col-xl-3 col-md-6 col-sm-12"}
                        titleClass={"list-desc-title"}
                        detailsClass={"list-desc-details"}
                      />
                      <DisplayItem
                        nameField={fields.lblEntity}
                        valueField={item.EntityType}
                        outClass={"col-xxl-2 col-xl-2 col-md-6 col-sm-12"}
                        titleClass={"list-desc-title"}
                        detailsClass={"list-desc-details"}
                      />
                      <DisplayItem
                        nameField={fields.lblCTPrimary}
                        valueField={item.Primary}
                        outClass={"col-xxl-3 col-xl-3 col-md-6 col-sm-12"}
                        titleClass={"list-desc-title"}
                        detailsClass={"list-desc-details"}
                      />
                    </div>
                    <div className="row mt15">
                      <DisplayItem
                        nameField={fields.lblCTCategory}
                        valueField={item.Category}
                        outClass={"col-xxl-4 col-xl-4 col-md-6 col-sm-12"}
                        titleClass={"list-desc-title"}
                        detailsClass={"list-desc-details"}
                      />
                      <DisplayItem
                        nameField={fields.lblDelegateCareManager}
                        valueField={item.DelegatedCareManager}
                        outClass={"col-xxl-3 col-xl-3 col-md-6 col-sm-12"}
                        titleClass={"list-desc-title"}
                        detailsClass={"list-desc-details"}
                      />
                      <DisplayItem
                        nameField={fields.lblCaseMangerStatus}
                        valueField={item.CaseManagerStatus}
                        outClass={"col-xxl-3 col-xl-3 col-md-6 col-sm-12"}
                        titleClass={"list-desc-title"}
                        detailsClass={"list-desc-details"}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </>
      )}
    </>
  );
};

export default CareCoordinationTeam;
