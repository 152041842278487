import React, { useContext, useEffect, useState } from "react";
import {
  Image,
  Link,
  File,
  getFieldValue,
  isExperienceEditorActive,
} from "@sitecore-jss/sitecore-jss-react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AppContext } from "../../contexts/app.context";
import RenderSSOLink from "./RenderSSOLink";
import * as CommonAPI from "../../services/Common";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
import {
  NY,
  MEDICAID,
  SPANISH_LANG_CODE,
  CHINESE_LANG_CODE,
  ENGLISH_LANG_CODE,
  SPANISH_LANG_TEXT,
  CHINESE_LANG_TEXT,
  MARKETPLACE,
  HELP_FULLINK_ID,
  NY_HELPFUL_LINK_ID,
} from "../../define.constants";
import {
  settings,
  __accordantSSO,
  __document,
  __link,
  __planSpecificDocument,
  __sso,
  __dictionaryDocument,
} from "./constants";
import { InnerSpinner } from "../../common/Spinner";
import { YearDropdownContext } from "../../contexts/yearDropdown.context";
import { useGetDDLYearsAPI } from "../../hooks/useGetDDLYearsAPI";
import { getHelpfulLink } from "./../../utils/helpers";
import NonMarketplaceDocument from "./NonMarketplaceDocument";

function LinksList(props) {
  const helpfulLinksList = props.helpfulLinksList;
  let TOGGLE_YEAR = props.TOGGLE_YEAR;
  const [distItem, setDistItem] = useState(null);
  const {
    showAccordant,
    MemberStateName,
    memberLOB,
    programId,
    planId,
    prefLang,
    planTerminationDt,
    isNYAffinityMember,
  } = useContext(AppContext);
  const { yearFromMyCoverageDropdown, setYearFromMyCoverageDropdown } =
    useContext(YearDropdownContext);
  const [selectedYear, setSelectedYear] = useState("");
  const [finalPlanId, setFinalPlanId] = useState("");
  const { listRecords } = useGetDDLYearsAPI();
  const nyHelpfulLinkIds = NY_HELPFUL_LINK_ID;
  const helpfulLinkIds = HELP_FULLINK_ID;

  useEffect(() => {
    setFinalPlanId(planId);
    planTerminationDt !== undefined &&
      planTerminationDt !== null &&
      planTerminationDt !== "" &&
      setSelectedYear(new Date(planTerminationDt).getFullYear());
  }, [planId]);

  useEffect(() => {
    if (yearFromMyCoverageDropdown) {
      setSelectedYear(yearFromMyCoverageDropdown?.Year);
      setFinalPlanId(yearFromMyCoverageDropdown?.PlanID);
    }
  }, [yearFromMyCoverageDropdown]);

  useEffect(() => {
    if (prefLang !== "" && prefLang !== null) {
      const langcode =
        SPANISH_LANG_TEXT.toLowerCase() === prefLang?.toLowerCase() ||
        SPANISH_LANG_CODE.toLocaleLowerCase() === prefLang?.toLocaleLowerCase()
          ? SPANISH_LANG_CODE
          : CHINESE_LANG_TEXT.toLowerCase() === prefLang?.toLowerCase() ||
            CHINESE_LANG_CODE.toLocaleLowerCase() ===
              prefLang?.toLocaleLowerCase()
          ? CHINESE_LANG_CODE
          : ENGLISH_LANG_CODE;
      CommonAPI.getDictionaryItems(langcode)
        .then((response) => {
          setDistItem(response);
        })
        .catch(function (error) {
          console.log(
            "Error occured while fetching data from dictionary",
            error
          );
        });
    }
  }, [prefLang]);

  if (isExperienceEditorActive()) {
    return (
      <div className="links">
        <Slider {...settings}>
          {helpfulLinksList?.map((listItem, index) => (
            <div className="carousel-item-block" key={index}>
              <div className="card ">
                <Image
                  className="carousel-icon"
                  key={index}
                  field={listItem.fields.image}
                />
                <div>
                  <div>
                    <Link
                      aria-label={listItem.fields.link}
                      field={listItem.fields.link}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  function DocOrLinkUnavailability(helpfulLinkValue) {
    let linkUnavailablility = false;
    if (
      (helpfulLinkValue === "#" ||
        helpfulLinkValue === "" ||
        helpfulLinkValue === undefined) &&
      selectedYear < new Date().getFullYear()
    ) {
      linkUnavailablility = true;
    }
    return linkUnavailablility;
  }

  function NYExclusion(fields) {
    let linkIds = [];

    // find matching entry in define.constants
    nyHelpfulLinkIds.forEach((link) => {
      if (link.id === getFieldValue(fields, "id")) {
        linkIds = link.supportedPrograms;
      }
    });

    // no match found
    if (linkIds?.length < 1) {
      return false; //this is not an excuded item
    }

    let remove = true;
    linkIds?.forEach((program) => {
      // check for matching program id
      if (
        program.programId.trim().toUpperCase() ===
        programId.trim().toUpperCase()
      ) {
        // check for appropriate affinity statues
        if (
          (program.Affinity === "Y" && isNYAffinityMember) ||
          (program.Affinity === "N" && !isNYAffinityMember) ||
          program.Affinity === "B"
        ) {
          remove = false; //link is applicable for this user
        }
      }
    });
    return remove; //link is not applicable for this user
  }

  function handleHelperLinks(fields, index) {
    let comingSoonKey = MemberStateName + "~comingSoonYearUrl";
    let linkIds = [];

    // checki if member is NY Medicaid and therefore subject to NY Exclusion
    if (
      MemberStateName.trim().toUpperCase() == NY.trim().toUpperCase() &&
      memberLOB.trim().toUpperCase() == MEDICAID.trim().toUpperCase()
    ) {
      if (NYExclusion(fields)) {
        return; // this item is not applicable to this memeber
      }
    }

    helpfulLinkIds.forEach((link) => {
      if (link.id === getFieldValue(fields, "id")) {
        linkIds = link;
      }
    });

    if (linkIds && linkIds.programIds && linkIds.programIds.length > 0) {
      if (linkIds.programIds.includes(programId)) {
        if (
          linkIds.planIds &&
          linkIds.planIds.length > 0 &&
          !linkIds.planIds.includes(planId)
        ) {
          return;
        }
      } else {
        return;
      }
    }

    switch (getFieldValue(fields?.helpfulLinkTypes?.fields, "text")) {
      case __accordantSSO:
        return showAccordant ? (
          <RenderSSOLink key={index} fields={fields} />
        ) : null;
      case __document:
        return (
          <div className="carousel-item-block" key={index}>
            <File field={fields.doc} target="_blank" rel="noopener noreferrer">
              <div className="card ">
                <Image
                  className="carousel-icon"
                  key={index}
                  field={fields.image}
                />
                <div>
                  <div>{getFieldValue(fields, "doc")?.title}</div>
                </div>
              </div>
            </File>
          </div>
        );
      case __link:
        let linkUrl = "";
        let linkUrlParams = {
          yearsList: fields?.helpfullinkYearList,
          comingSoonYear: distItem?.phrases.comingSoonYear?.toString(),
          comingSoonYearUrl: distItem?.phrases[comingSoonKey],
        };
        if (
          selectedYear?.toString() &&
          fields?.helpfullinkYearList?.length > 1
        ) {
          linkUrl = getHelpfulLink({
            year: selectedYear?.toString(),
            ...linkUrlParams,
          });
        } else {
          linkUrl = getHelpfulLink({
            ...linkUrlParams,
          });
        }
        let helpfulLinkValue = "";
        if (
          linkUrl?.value !== null &&
          linkUrl?.value !== undefined &&
          linkUrl?.value !== ""
        ) {
          if (
            linkUrl?.value?.href !== "" ||
            linkUrl?.value?.href !== "http://#"
          ) {
            helpfulLinkValue = linkUrl?.value?.href;
          } else {
            helpfulLinkValue = "#";
          }
        } else helpfulLinkValue = linkUrl;
        return (
          <div className="carousel-item-block" key={index}>
            <WhoIsAuthorized
              type="link"
              href={helpfulLinkValue}
              actionUnavailable={DocOrLinkUnavailability(helpfulLinkValue)}
            >
              {({ handleClick, href }) => {
                return (
                  <a
                    href={href}
                    onClick={handleClick}
                    {...(linkUrl?.value?.target ? { target: "_blank" } : {})}
                    rel="noopener noreferrer"
                  >
                    <div className="card ">
                      <Image
                        className="carousel-icon"
                        key={index}
                        field={fields?.image}
                      />
                      <div>
                        <div>{getFieldValue(fields, "helpfullinkTitle")}</div>
                      </div>
                    </div>
                  </a>
                );
              }}
            </WhoIsAuthorized>
            {/* </Link> */}
          </div>
        );
      case __planSpecificDocument:
      case __dictionaryDocument:
        const keys = distItem !== null ? Object.keys(distItem?.phrases) : null;
        if (memberLOB.toLocaleLowerCase() == MARKETPLACE.toLocaleLowerCase()) {
          let param =
            MemberStateName +
            `~` +
            memberLOB +
            `~` +
            fields?.docName?.value +
            `~` +
            finalPlanId +
            `~`;
          let param1 =
            MemberStateName + `~` + memberLOB + `~` + fields?.docName?.value;
          let planurl = "#";
          if (keys !== null && keys !== undefined) {
            keys.forEach((item) => {
              if (
                (selectedYear &&
                  (item
                    .toLocaleLowerCase()
                    .includes((param + selectedYear).toLocaleLowerCase()) ||
                    (item
                      .toLocaleLowerCase()
                      .includes(
                        (param1 + "~" + selectedYear).toLocaleLowerCase()
                      ) &&
                      fields?.docName?.value === "Handbook"))) ||
                ((selectedYear == null || selectedYear == "") &&
                  (item
                    .toLocaleLowerCase()
                    .includes(param.toLocaleLowerCase()) ||
                    (item
                      .toLocaleLowerCase()
                      .includes(param1.toLocaleLowerCase()) &&
                      fields?.docName?.value === "Handbook")) &&
                  item.includes("Common"))
              ) {
                planurl = distItem?.phrases[item];
                return planurl;
              }
            });
          }
          if (
            selectedYear == distItem?.phrases?.comingSoonYear &&
            planurl === "#"
          ) {
            planurl = distItem?.phrases[comingSoonKey];
          }
          if (
            fields?.excludePlanIds?.value === "" ||
            (fields?.excludePlanIds?.value !== "" &&
              fields?.excludePlanIds?.value
                .toString()
                .split(",")
                .indexOf(planId) < 0)
          ) {
            return (
              <div className="carousel-item-block" key={index}>
                <WhoIsAuthorized
                  type="link"
                  href={planurl}
                  actionUnavailable={DocOrLinkUnavailability(planurl)}
                >
                  {({ handleClick, href }) => {
                    return (
                      <a
                        id={`a_downloadPlanIcon${index}`}
                        href={href}
                        onClick={handleClick}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="card ">
                          <Image
                            className="carousel-icon"
                            key={index}
                            field={fields?.image}
                          />
                          <div>
                            <div>{getFieldValue(fields, "title")}</div>
                          </div>
                        </div>
                      </a>
                    );
                  }}
                </WhoIsAuthorized>
              </div>
            );
          } else {
            return null;
          }
        } else {
          return (
            <NonMarketplaceDocument
              DocOrLinkUnavailability={DocOrLinkUnavailability}
              selectedYear={selectedYear}
              index={index}
              distItem={distItem}
              fields={fields}
              TOGGLE_YEAR={TOGGLE_YEAR}
            />
          );
        }

      case __sso:
        return <RenderSSOLink key={index} fields={fields} />;
      default:
        return null;
    }
  }

  return (
    <div className="links">
      {helpfulLinksList?.length > 0 ? (
        <Slider {...settings}>
          {helpfulLinksList.map((listItem, index) => {
            return handleHelperLinks(listItem.fields, index);
          })}
        </Slider>
      ) : (
        <InnerSpinner />
      )}
    </div>
  );
}

export default LinksList;
