import React, { useContext } from "react";
import { Placeholder, Text } from "@sitecore-jss/sitecore-jss-react";
import { SSORedirectProvider } from "../../contexts/SSORedirect.context";
import { MyResourcesProvider } from "../../contexts/myResources.context";
import { AppContext } from "./../../contexts/app.context";
import {
  NY,
  MARKETPLACE,
  MEDICARE,
  DUALS,
  MEDICAID,
  MEDICARE_CY2024_MAC_STATES,
  TX,
  TX_MEDICAID_STAR_PLUS,
  CA,
} from "../../define.constants";

const MyResourcesLayout = (props) => {
  const { rendering, fields } = props;
  const { MemberStateName, memberLOB, programId } = useContext(AppContext);
  let currentyear = new Date().getFullYear();
  let macFlag = MEDICARE_CY2024_MAC_STATES?.includes(
    MemberStateName?.trim()?.toLowerCase()
  );

  const isMarketplaceMember =
    memberLOB.toLowerCase() === MARKETPLACE.toLowerCase();
  const isMedicare = memberLOB.toLowerCase() === MEDICARE.toLowerCase();
  const isTXStarPlusMedicaid =
    MemberStateName === TX && programId === TX_MEDICAID_STAR_PLUS;
  const isDuals = memberLOB.toLowerCase() === DUALS.toLowerCase();
  const isMedicaid = memberLOB.toLowerCase() === MEDICAID.toLowerCase();
  const isNYMedicaid = MemberStateName === NY && isMedicaid;
  const isCAMedicaid = MemberStateName === CA && isMedicaid;
  const isCAMarketplaceMember = MemberStateName === CA && isMarketplaceMember;

  const hiddenTabs = () => {
    let tabsToHide = [];
    if (!isTXStarPlusMedicaid && !isNYMedicaid) {
      tabsToHide.push("MyResources-ELetter");
    }
    if (!(MemberStateName === "SC" && isMedicaid)) {
      tabsToHide.push("MyResources-PregnancyandYourBaby");
    }
    if (!(isCAMedicaid || isCAMarketplaceMember)) {
      tabsToHide.push("MyResources-WellnessBenefitsandPrograms");
    }
    if (
      isMarketplaceMember ||
      isDuals ||
      isMedicaid ||
      (isMedicare && macFlag === false)
    ) {
      tabsToHide.push("MyResources-MemberAdvisoryCommittee");
    }

    return tabsToHide;
  };

  return (
    <MyResourcesProvider>
      <SSORedirectProvider>
        <div className="my-resources">
          <div className="row">
            <div className="col-12">
              <div className="page-heading">
                <Text field={fields?.heading ?? ""} />
              </div>
            </div>
          </div>
          <Placeholder
            name="jss-my-resources-layout"
            rendering={rendering}
            hiddenTabs={hiddenTabs()}
          />
        </div>
      </SSORedirectProvider>
    </MyResourcesProvider>
  );
};

export default MyResourcesLayout;
