import React, { useContext, useEffect, useState } from "react";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import TopHeader from "./TopHeader";
import TopNavigation from "./TopNavigation";
import LeftNavigation from "./LeftNavigation";
import { AppContext } from "../../contexts/app.context";
import {
  getStateConfiguration,
  redirectTo,
  showMyBillPopup,
} from "./../../utils/helpers";
import {
  GLOBAL_VARIABLE,
  NM,
  NY,
  MEDICAID,
  NY_MD_CHP_PROGRAMID,
  NY_MD_NMC_PROGRAMID,
  MARKETPLACE,
} from "../../define.constants";
import { withSSOProvider } from "../../hoc/withSSOProvider";
import { SSORedirectContext } from "../../contexts/SSORedirect.context";
import { ModalContext } from "../../contexts/modal.context";
import MyBillModal from "../Home-MyBill/MyBillModal";

const Header = (props) => {
  const {
    memberLOB,
    MemberStateName,
    memberIDMaster,
    programId,
    isNYAffinityMember,
  } = useContext(AppContext);
  const { redirectToSSO } = useContext(SSORedirectContext);
  const { openModal } = useContext(ModalContext);
  const [showLeftNav, setShowLeftNav] = useState(false);
  const navList = getStateConfiguration(
    props?.fields,
    MemberStateName,
    memberLOB
  );
  const isMarketplaceMember =
    memberLOB?.toLowerCase() === MARKETPLACE?.toLowerCase();
  const isNYChip =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === NY &&
    !isMarketplaceMember &&
    programId === NY_MD_CHP_PROGRAMID;
  const myBillLink = getFieldValue(navList?.fields, "MyBill", false);
  const leftNavActiveClass = showLeftNav ? "active-leftnav" : "";
  const openNav = (e) => {
    e.preventDefault();
    setShowLeftNav(true);
  };
  const closeNav = (e) => {
    e.preventDefault();
    setShowLeftNav(false);
  };
  useEffect(() => {
    window.onscroll = scrollFunction;
  }, []);

  useEffect(() => {
    if (!sessionStorage.getItem("GBDetector")) {
      if (MemberStateName != "" && memberLOB != "" && memberIDMaster != "") {
        const memberDetails = {
          state: MemberStateName,
          memberLOB: memberLOB,
          member_id: memberIDMaster,
        };
        try {
          var returnObject = _detector.triggerCustomEventMap(
            "memberDetails",
            memberDetails
          );
          if (
            returnObject != null &&
            returnObject.status != null &&
            returnObject.status == "success"
          ) {
            sessionStorage.setItem("GBDetector", "true");
          } else {
            console.error("Detector call failed");
          }
        } catch (e) {
          console.error("Error in triggering custom event", e);
        }
      }
    }
  }, [MemberStateName, memberLOB, memberIDMaster]);

  const scrollFunction = () => {
    if (
      document.body.scrollTop > 94 ||
      document.documentElement.scrollTop > 94
    ) {
      document.getElementById("header").classList.add("header-fix");
    } else {
      document.getElementById("header").classList.remove("header-fix");
    }
  };
  GLOBAL_VARIABLE.lblCalenderPlaceholder = getFieldValue(
    props?.fields,
    "lblCalenderPlaceholder",
    false
  );
  GLOBAL_VARIABLE.lblAltCalender = getFieldValue(
    props?.fields,
    "lblAltCalender",
    false
  );
  GLOBAL_VARIABLE.lnkHome = getFieldValue(props?.fields, "lnkHome", false);
  GLOBAL_VARIABLE.lblSelectDate = getFieldValue(
    props?.fields,
    "lblSelectDate",
    false
  );
  GLOBAL_VARIABLE.noOptionMessageSelect = getFieldValue(
    props?.fields,
    "noOptionMessageSelect",
    false
  );
  GLOBAL_VARIABLE.lblSelectPlaceholder = getFieldValue(
    props?.fields,
    "lblSelectPlaceholder",
    false
  );

  const onMyBillClick = (e) => {
    if (showMyBillPopup({ stateCode: MemberStateName })) {
      e.preventDefault();
      openModal({ content: <MyBillModal {...navList?.fields} /> });
    } else {
      if (MemberStateName === NM) {
        redirectTo(myBillLink?.href, false, myBillLink?.target);
      } else if (MemberStateName === NY && isNYChip === true) {
        redirectToSSO({
          e,
          url: constants.apigeeURLs.InstaMedSSORedirect,
          data: {
            ReqPage: "Home",
            StrFrom: "MAKEPAYMENT",
          },
        });
      } else {
        redirectToSSO({
          e,
          url: constants.apigeeURLs.GetHPSSSORedirect,
          data: {
            ReqPage: "ViewMyBill",
          },
        });
      }
    }
  };
  return (
    <>
      <header id="header">
        <TopHeader fields={navList?.fields} openNav={openNav} />
        <TopNavigation fields={navList?.fields} onMyBillClick={onMyBillClick} />
      </header>

      {showLeftNav && (
        <div
          className="modal-backdrop fade show"
          onClick={(e) => {
            closeNav(e);
          }}
        ></div>
      )}
      <aside id="leftSidenav" className={`sidenav ${leftNavActiveClass}`}>
        <LeftNavigation
          fields={navList?.fields}
          closeNav={closeNav}
          onMyBillClick={onMyBillClick}
        />
      </aside>
    </>
  );
};

export default withSSOProvider(Header);
