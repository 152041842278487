import React, { useContext, useState, useEffect } from "react";
import {
  Link,
  withSitecoreContext,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { useLocation } from "react-router-dom";
import {
  ENGLISH_LANG_CODE,
  SPANISH_LANG_CODE,
  CHINESE_LANG_CODE,
  SPANISH_LANG_TEXT,
  ENGLISH_LANG_TEXT,
  CHINESE_LANG_TEXT,
  DELIMITER_COLON,
  DEPENDANT_NO_ACCESS_ROLEID,
  KY,
  MARKETPLACE,
  NM,
  MEDICARE,
  IA,
  NY,
  NY_MD_SWHFIDE_PROGRAMID,
  NY_MC_SWH_PROGRAMID,
  TX_MEDICAID_STAR_PLUS,
  TX_MEDICAID_STAR,
  TX_MEDICAID_CHIP,
  TX_MEDICAID_CHIP_RSA,
  TX_MEDICAID_CHIP_PERINATE,
  TX_MEDICAID_CHIP_PERINATE_RSA,
  TX_DUALS_49,
  MI_DUAL,
  SC_MEDICAID,
  SC,
  DUALS,
  SC_DUAL_MCAID,
  SC_DUAL_MCARE,
  SWH_PROGRAMS,
  MA,
  OH,
  NE,
  NM_MEDICAID_ProgramID,
} from "../../define.constants";
import { ICON_PATH, SUPPORT_USER, TERMED_MEMBER } from "../../define.constants";
import { AppContext } from "../../contexts/app.context";
import { SpinnerContext } from "../../contexts/spinner.context";
import * as UpdateLanguageAPI from "../../services/UpdateLanguageAPI";
import Logout from "../Logout";
import {
  GLOBAL_VARIABLE,
  NYMCC_PROGRAMID,
  MEDICAID,
} from "../../define.constants";
import { HEADER_USER_SWITCH } from "../../define.widgets";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
import { InnerSpinner } from "../../common/Spinner";
import { brightCarePortal } from "../../utils/helpers";

function TopHeader(props) {
  const { fields, openNav } = props;
  const {
    unreadInboxMsgCount,
    Dependents,
    changeDependentView,
    memberRKSID,
    refreshMemberInfoAndReload,
    isMemberAPICalled,
    prefLang,
    selectedMemberNumber,
    selectedMemberName,
    userType,
    showUserSwitch,
    roleId,
    MemberStateName,
    refreshGetAccountProfileAPI,
    memberLOB,
    isNYAffinityMember,
    programId,
    isSupportUser,
    supportUserId,
    isCABrightcareMember,
  } = useContext(AppContext);

  const { setSpinner, innerSpinnerHTML } = useContext(SpinnerContext);

  const [isBrightCare, setIsBrightCare] = useState(null);

  const [molinaLogo, setMolinaLogo] = useState(
    <div className="inner-spinner-without-overlay">
      <InnerSpinner />
    </div>
  );

  const [
    languageOne,
    languageTwo,
    languageThree,
    lnkNewMessagesText,
    lnkNoNewMessagesText,
  ] = [
    getFieldValue(fields, "languageOne", false),
    getFieldValue(fields, "languageTwo", false),
    getFieldValue(fields, "languageThree", false),
    getFieldValue(fields, "lnkNewMessages", false).text,
    getFieldValue(fields, "lnkNoNewMessages", false).text,
  ];

  const loc = useLocation();

  const SetLogo = () => {
    if (MemberStateName === IA) {
      return `Logo_Image_IA.png`;
    } else if (MemberStateName === NE) {
      return `Logo_Image_NE.png`;
    } else if (MemberStateName === NM && programId === NM_MEDICAID_ProgramID) {
      return `Logo_Image_NM.png`;
    }
  };
  const langURL = `${loc?.pathname
    .replace(`/${SPANISH_LANG_CODE}`, "")
    .replace(`/${ENGLISH_LANG_CODE}`, "")
    .replace(`/${CHINESE_LANG_CODE}`, "")}`;

  const currentLanguage = prefLang?.toLowerCase();
  const langText =
    currentLanguage === SPANISH_LANG_TEXT.toLowerCase() ||
    currentLanguage === SPANISH_LANG_CODE.toLowerCase()
      ? getFieldValue(fields, "languageSPA", false)
      : currentLanguage === CHINESE_LANG_TEXT.toLowerCase() ||
        currentLanguage === CHINESE_LANG_CODE.toLowerCase()
      ? getFieldValue(fields, "languageCHN", false)
      : getFieldValue(fields, "languageENG", false); //label should be from sitecore.

  const isNYMLTCProgram =
    MemberStateName === NY &&
    memberLOB?.toLowerCase() === MEDICAID &&
    programId === NYMCC_PROGRAMID;
  const isNYSWHFIDEMedicaid =
    MemberStateName === NY &&
    memberLOB?.toLowerCase() === MEDICAID &&
    programId === NY_MD_SWHFIDE_PROGRAMID;
  const isNYSWHMedicare =
    MemberStateName === NY &&
    memberLOB?.toLowerCase() === MEDICARE &&
    programId === NY_MC_SWH_PROGRAMID;
  const memberStateName = MemberStateName;
  const isTXCHIP =
    programId === TX_MEDICAID_CHIP ||
    programId === TX_MEDICAID_CHIP_PERINATE ||
    programId === TX_MEDICAID_CHIP_PERINATE_RSA ||
    programId === TX_MEDICAID_CHIP_RSA;
  const isSCMedicaid =
    memberLOB?.toLowerCase() === MEDICAID &&
    MemberStateName === SC &&
    programId === SC_MEDICAID;
  const isSWHMember = SWH_PROGRAMS.includes(programId);

  const isSCDual =
    memberLOB?.toLowerCase() === DUALS &&
    MemberStateName === SC &&
    (programId === SC_DUAL_MCAID || programId === SC_DUAL_MCARE);

  const isOHDual = memberLOB?.toLowerCase() === DUALS && MemberStateName === OH;

  const RightLabel = () => {
    if (memberStateName === NY && isNYAffinityMember === true) {
      return <span>{getFieldValue(fields, "lblNYAffinity", false)}</span>;
    } else if (isNYSWHMedicare || isSWHMember) {
      return <span>{getFieldValue(fields, "lblSWH", false)}</span>;
    } else if (isNYMLTCProgram || isNYSWHFIDEMedicaid) {
      return <span>{getFieldValue(fields, "lblNYMEdicaidSWH", false)}</span>;
    } else if (programId === MI_DUAL) {
      return (
        <img
          src={`${ICON_PATH}/MDHHS_MiHealthLink_SubBrand_logo_horizontal_rev.png`}
          alt="Michigan Health Link Logo"
          style={{ height: "50px" }}
        />
      );
    } else if (isOHDual) {
      return <span></span>;
    } else {
      return <span>{getFieldValue(fields, "lblMyMolina", false)}</span>;
    }
  };
  const SCMedicaidBranding = () => {
    return (
      <img
        src={`${ICON_PATH}/SC_Molina_Healthy_Connections.jpg`}
        alt="SC Molina Healthcare Healthy Connections"
        className="TXlogoMrgn starPlusMob"
      />
    );
  };

  const SCDualBranding = () => {
    return (
      <img
        src={`${ICON_PATH}/Healthy-Connections-Prime.png`}
        alt="SC Molina Healthcare Healthy Connections Prime"
        className="TXlogoMrgn starPlusMob"
      />
    );
  };
  const OHDualBranding = () => {
    return (
      <img
        src={`${ICON_PATH}/OH_MyCare_Logo.png`}
        alt="SC Molina Healthcare Healthy Connections Prime"
        className="TXlogoMrgn starPlusMob"
      />
    );
  };
  const TXMedicaidBranding = () => {
    if (programId === TX_MEDICAID_STAR_PLUS) {
      return (
        <img
          src={`${ICON_PATH}/Texas_StarPlus.png`}
          alt="Star Plus Logo"
          className="TXlogoMrgn starPlusMob"
        />
      );
    } else if (programId === TX_MEDICAID_STAR) {
      return (
        <img
          src={`${ICON_PATH}/TexasStar.png`}
          alt="Star Logo"
          className="TXlogoMrgn"
        />
      );
    } else if (isTXCHIP) {
      return (
        <img
          src={`${ICON_PATH}/TexasCHIP.png`}
          alt="CHIP Logo"
          className="TXlogoMrgn starPlusMob"
        />
      );
    } else if (programId === TX_DUALS_49) {
      return (
        <img
          src={`${ICON_PATH}/TexasMMP.png`}
          alt="Duals Logo"
          className="TXlogoMrgn starPlusMob"
        />
      );
    }
    return;
  };

  const SetClassName = () => {
    if (memberStateName === "IA") {
      return `navbar-brand molina-header-logo-ia`;
    } else {
      return `navbar-brand molina-header-logo`;
    }
  };

  const SetClassNameHeader = () => {
    if (memberStateName === "IA") {
      return `molina-header1-ia`;
    } else {
      return `molina-header1`;
    }
  };

  const SetContainerClassName = () => {
    if (memberStateName === "IA") {
      return "container-fluid";
    } else {
      return "container-fluid mobilehead";
    }
  };

  useEffect(async () => {
    setIsBrightCare(await brightCarePortal());
  }, []);

  useEffect(() => {
    if (memberStateName) {
      if (isBrightCare != null) {
        setMolinaLogo(
          <>
            {isBrightCare ? (
              <>
                <img
                  src={`${ICON_PATH}/Central_HealthPlan_logo_320.svg`}
                  alt={getFieldValue(fields, "molinaIconAltText", false)}
                  className="call-icon text-zoom me-3"
                />
                <span>{getFieldValue(fields, "lblMyCHP", false)}</span>
              </>
            ) : (
              <>
                <img
                  src={`${ICON_PATH}/${
                    memberStateName === KY
                      ? "logo-passport.svg"
                      : memberStateName === NY && isNYAffinityMember === true
                      ? "Affinity_Molina_Healthcare_logo.png"
                      : isNYSWHMedicare ||
                        isNYSWHFIDEMedicaid ||
                        isNYMLTCProgram
                      ? "Molina_Healthcare_SWH_logo.png"
                      : memberStateName === MA && isSWHMember
                      ? "SWH_MA_Logo.png"
                      : "Molina_Healthcare_Logo_320.svg"
                  }`}
                  className={
                    memberStateName === NY && isNYAffinityMember === true
                      ? "Affinity_logo"
                      : isNYSWHMedicare ||
                        isNYSWHFIDEMedicaid ||
                        isNYMLTCProgram
                      ? "swh_logo"
                      : "call-icon text-zoom"
                  }
                  alt={getFieldValue(fields, "molinaIconAltText", false)}
                />
                {RightLabel()}

                {memberStateName === IA && (
                  <img
                    src={`${ICON_PATH}/${SetLogo()}`}
                    className="call-icon-ia"
                  />
                )}
                {memberStateName === NE && (
                  <span className="mrgnleft10">
                    <img
                      src={`${ICON_PATH}/${SetLogo()}`}
                      className="call-icon"
                    />
                  </span>
                )}
                {memberStateName === NM && programId === NM_MEDICAID_ProgramID && (
                  <span className="mrgnleft10">
                    <img src={`${ICON_PATH}/${SetLogo()}`} className="nmlogo" />
                  </span>
                )}
              </>
            )}
          </>
        );
      }
    }
  }, [
    memberStateName,
    isNYAffinityMember,
    isNYMLTCProgram,
    isNYSWHFIDEMedicaid,
    isNYSWHMedicare,
    isBrightCare,
  ]);

  const handleLanguageChange = (e, langCode) => {
    e.preventDefault();
    setSpinner(true);
    Genesys("command", "Messenger.close");
    UpdateLanguageAPI?.getUpdateLanguage({
      MemLanguage: langCode,
    })
      .then((response) => {
        if (response?.Message === "Success") {
          location.href = `/${langCode}${langURL}`;
          // refreshGetAccountProfileAPI(`/${langCode}${langURL}`);
        }
      })
      .catch(function () {
        setSpinner(false);
      })
      .finally(function () {
        setSpinner(false);
      });
  };

  const isMarketplaceMember = memberLOB?.toLowerCase() === MARKETPLACE;

  return (
    <>
      <nav
        className={`navbar navbar-expand-sm navbar-light bg-white ${SetClassNameHeader()}`}
      >
        <div className={SetContainerClassName()}>
          <ul className="navbar-nav">
            <li className="nav-item p-2">
              <a href="#" onClick={openNav}>
                <img
                  src={`${ICON_PATH}/hamburger-menu.svg`}
                  className="header-icon datcolor text-zoom"
                  alt={getFieldValue(fields, "menuIconAltText", false)}
                />
              </a>
            </li>
            <li className="nav-item molina-header-lbl1 active">
              <a
                className="nav-link"
                id="navbarDropdownMenuLink1"
                data-mdb-toggle="dropdown"
              >
                <div>
                  <div className="dropdown-toggle">
                    <span>
                      {getFieldValue(fields, "lblWelcome", false)}{" "}
                      {selectedMemberName}
                    </span>
                  </div>
                  <div>
                    {getFieldValue(fields, "lblMyID", false)}
                    {selectedMemberNumber}
                  </div>
                </div>
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink1"
              >
                {!isMarketplaceMember ||
                Dependents == null ||
                Dependents?.length < 1 ? (
                  <div></div>
                ) : (
                  <div>
                    {innerSpinnerHTML?.[HEADER_USER_SWITCH]}
                    {showUserSwitch &&
                      Dependents?.map((item, index) => {
                        if (item.MemberNumber !== selectedMemberNumber) {
                          return (
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                changeDependentView({
                                  memberId: item.RksMemberId,
                                  memberNumber: item.MemberNumber,
                                  index,
                                  isCurrentLogin: item.IsCurrentLogin,
                                  stateCode: item.StateCode,
                                  roleId: item.RoleId,
                                  relationshipToLoggedInUser:
                                    item.RelationshipToLoggedInUser,
                                  isSupportUser: isSupportUser && supportUserId,
                                });
                              }}
                              key={index}
                            >
                              <span className="header-drp-lbl">
                                {item.IsCurrentLogin
                                  ? `${item.RelationshipToLoggedInUser}${DELIMITER_COLON}`
                                  : `${item.FirstName} ${item.LastName}${DELIMITER_COLON}`.toLowerCase()}
                              </span>
                              <span className="header-drp-value">
                                {getFieldValue(fields, "lblID", false)}
                                {item.MemberNumber}
                              </span>
                            </a>
                          );
                        }
                      })}
                    {roleId !== DEPENDANT_NO_ACCESS_ROLEID ? (
                      <hr className="dropdown-divider" />
                    ) : null}
                  </div>
                )}

                <Link
                  field={getFieldValue(fields, "lnkAccountSettings", false)}
                  className="dropdown-item"
                />
                <Logout fields={fields} />
              </div>
            </li>
          </ul>
          {MemberStateName === IA ? (
            <Link
              className={`${SetClassName()} navbar-center text-zoom d-flex`}
              field={GLOBAL_VARIABLE.lnkHome}
            >
              {molinaLogo}
            </Link>
          ) : (
            <div className="navbar-brand navbar-center d-flex">
              <Link
                className="molina-header-logo"
                field={GLOBAL_VARIABLE.lnkHome}
              >
                {molinaLogo}
              </Link>
              {TXMedicaidBranding()}
              {(isSCMedicaid && SCMedicaidBranding()) ||
                (isSCDual && SCDualBranding())}
              {isOHDual && OHDualBranding()}
            </div>
          )}
          <ul className="navbar-nav navbar-right hidden-md">
            <li className="nav-item dropdown molina-header-lbl3 d-none d-md-block">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="drp_languages"
                data-mdb-toggle="dropdown"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <img
                  src={`${ICON_PATH}/language-selection.svg`}
                  className="header-icon datacolor text-zoom"
                  alt={getFieldValue(fields, "languageIconAltText", false)}
                />
                <div>{langText}</div>
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <WhoIsAuthorized
                  type="link"
                  href="#"
                  authorize={{
                    [SUPPORT_USER]: false,
                    [TERMED_MEMBER]: true,
                  }}
                  handleClick={(e) => {
                    handleLanguageChange(e, ENGLISH_LANG_CODE);
                  }}
                >
                  {({ handleClick, disabledClass }) => {
                    return (
                      <a
                        href="#"
                        className={`dropdown-item ${
                          currentLanguage?.toLowerCase() ===
                            ENGLISH_LANG_TEXT.toLowerCase() ||
                          currentLanguage?.toLowerCase() ===
                            ENGLISH_LANG_CODE.toLowerCase()
                            ? "active"
                            : ""
                        }`}
                        onClick={handleClick}
                      >
                        <img
                          src={
                            isCABrightcareMember
                              ? `${ICON_PATH}/chp-tick-teal.svg`
                              : `${ICON_PATH}/tick-teal.svg`
                          }
                          className="header-dropdown-icon"
                          alt={languageOne}
                        />
                        <span>{languageOne}</span>
                      </a>
                    );
                  }}
                </WhoIsAuthorized>
                <WhoIsAuthorized
                  type="link"
                  href="#"
                  authorize={{
                    [SUPPORT_USER]: false,
                    [TERMED_MEMBER]: true,
                  }}
                  handleClick={(e) => {
                    handleLanguageChange(e, SPANISH_LANG_CODE);
                  }}
                >
                  {({ handleClick, disabledClass }) => {
                    return (
                      <a
                        href="#"
                        className={`dropdown-item ${
                          currentLanguage?.toLowerCase() ===
                            SPANISH_LANG_TEXT.toLowerCase() ||
                          currentLanguage?.toLowerCase() ===
                            SPANISH_LANG_CODE.toLowerCase()
                            ? "active"
                            : ""
                        }`}
                        onClick={handleClick}
                      >
                        <img
                          src={
                            isCABrightcareMember
                              ? `${ICON_PATH}/chp-tick-teal.svg`
                              : `${ICON_PATH}/tick-teal.svg`
                          }
                          className="header-dropdown-icon"
                          alt={languageTwo}
                        />
                        <span>{languageTwo}</span>
                      </a>
                    );
                  }}
                </WhoIsAuthorized>
                {isBrightCare ? (
                  <WhoIsAuthorized
                    type="link"
                    href="#"
                    authorize={{
                      [SUPPORT_USER]: false,
                      [TERMED_MEMBER]: true,
                    }}
                    handleClick={(e) => {
                      handleLanguageChange(e, CHINESE_LANG_CODE);
                    }}
                  >
                    {({ handleClick, disabledClass }) => {
                      return (
                        <a
                          href="#"
                          className={`dropdown-item ${
                            currentLanguage?.toLowerCase() ===
                              CHINESE_LANG_TEXT.toLowerCase() ||
                            currentLanguage?.toLowerCase() ===
                              CHINESE_LANG_CODE.toLowerCase()
                              ? "active"
                              : ""
                          }`}
                          onClick={handleClick}
                        >
                          <img
                            src={
                              isCABrightcareMember
                                ? `${ICON_PATH}/chp-tick-teal.svg`
                                : `${ICON_PATH}/tick-teal.svg`
                            }
                            className="header-dropdown-icon"
                            alt={languageThree}
                          />
                          <span>{languageThree}</span>
                        </a>
                      );
                    }}
                  </WhoIsAuthorized>
                ) : (
                  ""
                )}
              </div>
            </li>
            <li className="nav-item dropdown molina-header-lbl3 justify-content-right d-none d-md-block">
              <a
                className="nav-link dropdown-toggle datcolor text-zoom"
                href="#"
                id="drp_notifications"
                data-mdb-toggle="dropdown"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <>
                  {unreadInboxMsgCount > 0 ? (
                    <img
                      src={`${ICON_PATH}/notification-unseen.svg`}
                      className="header-icon-msg"
                      alt={lnkNewMessagesText}
                    />
                  ) : (
                    <img
                      src={`${ICON_PATH}/notification-seen.svg`}
                      className="header-icon-msg"
                      alt={lnkNoNewMessagesText}
                    />
                  )}
                </>
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <>
                  {unreadInboxMsgCount > 0 ? (
                    <Link
                      field={getFieldValue(fields, "lnkNewMessages", false)}
                      className="dropdown-item p-4"
                    />
                  ) : (
                    <Link
                      field={getFieldValue(fields, "lnkNoNewMessages", false)}
                      className="dropdown-item p-4"
                    />
                  )}
                </>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}
export default withSitecoreContext()(TopHeader);
