import React, { useState, useContext, useEffect } from "react";
import { getFieldValue, Text } from "@sitecore-jss/sitecore-jss-react";
import { withModalProvider } from "../../hoc/withModalProvider";
import { AppContext } from "../../contexts/app.context";
import { getStateLobConfigurationItems } from "./../../utils/helpers";
import * as LiveChatAuditingAPI from "../../services/Common";
import {
  GLOBAL_VARIABLE,
  GENESYS_USERTYPE,
  MEDICAID,
  MEDICARE,
  MARKETPLACE,
  ICON_PATH,
  GENESYS_MEDICAID,
  GENESYS_MEDICARE,
  GENESYS_MARKETPLACE,
} from "../../define.constants.js";

const LiveChat = (props) => {
  let {
    selectedMemberNumber,
    memberLOB,
    MemberStateName,
    prefLang,
    zip,
    dateOfBirth,
    prefPhone,
    homePhone,
    cellPhone,
    memberStateCode,
    planId,
    isMemInfoResponseLoaded,
    isTermedMember,
    isSupportUser,
    programId,
    isCABrightcareMember,
    profileList,
  } = useContext(AppContext);

  const [chatInitiate, setChatInitiate] = useState(true);
  const isMarketplaceMember =
    memberLOB.toLowerCase() === MARKETPLACE.toLowerCase();

  function toggleMessenger() {
    localStorage.setItem("chatEnable", "true");
    sessionStorage.setItem("browserClosed", "true");
    Genesys(
      "command",
      "Messenger.open",
      {},
      function (o) {}, // if resolved
      function (o) {
        // if rejected
        Genesys("command", "Messenger.close");
      }
    );
  }

  const callGenesys = () => {
    function UpdateCallData(
      MemberId,
      DOB,
      Zip,
      State,
      Intent,
      Segmentation,
      Language,
      PhoneNumber,
      LOB,
      PlanId
    ) {
      let memberLOB = "";
      if (LOB.toLowerCase() == MEDICAID) {
        memberLOB = GENESYS_MEDICAID;
      } else if (LOB.toLowerCase() == MEDICARE) {
        memberLOB = GENESYS_MEDICARE;
      } else if (LOB.toLowerCase() == MARKETPLACE) {
        memberLOB = GENESYS_MARKETPLACE;
      }

      if (State === "XY" && MemberStateName === "UT") {
        if (PlanId === "QMXBP7597") {
          Segmentation = "DSNP";
        } else if (PlanId === "QMXBP8373") {
          Segmentation = "MAPD";
        }
      }

      if (State === "XY") {
        State = MemberStateName;
      }

      MemberId = isMarketplaceMember ? profileList.MemberId : MemberId;
      DOB = isMarketplaceMember ? profileList.DateOfBirth : DOB;
      Zip = isMarketplaceMember ? profileList.Zip : Zip;
      PhoneNumber = isMarketplaceMember ? profileList.HomePhone : PhoneNumber;

      Genesys("command", "Database.set", {
        messaging: {
          customAttributes: {
            MemberID: MemberId,
            MemberDOB: DOB,
            MemberZIP: Zip,
            State: State,
            Intent: Intent,
            Segmentation: Segmentation,
            Language: Language,
            PhoneNumber: PhoneNumber,
            LOB: memberLOB,
            PlanID: PlanId,
            UserType: GENESYS_USERTYPE,
          },
        },
      });

      let liveChatAuditData = {
        Intent: Intent,
        Segmentation: Segmentation,
        Language: Language,
      };

      if (liveChatAuditData && MemberId != 0 && DOB != "") {
        LiveChatAuditingAPI.LiveChatAuditing(liveChatAuditData);
      }
    }

    let phoneNumber = "";
    if (prefPhone != null && prefPhone != "") {
      phoneNumber = prefPhone;
    } else if (homePhone != null && homePhone != "") {
      phoneNumber = homePhone;
    } else if (cellPhone != null && cellPhone != "") {
      phoneNumber = cellPhone;
    }

    let genesysIntent = "";
    if (GLOBAL_VARIABLE?.genesysIntent) {
      genesysIntent = GLOBAL_VARIABLE?.genesysIntent;
    }

    UpdateCallData(
      selectedMemberNumber,
      dateOfBirth,
      zip,
      memberStateCode,
      genesysIntent,
      "",
      prefLang,
      phoneNumber,
      memberLOB,
      planId
    );
  };

  const liveChatField = getStateLobConfigurationItems(
    props.fields,
    MemberStateName,
    memberLOB,
    programId
  );

  useEffect(() => {
    if (
      chatInitiate &&
      selectedMemberNumber != 0 &&
      dateOfBirth != "" &&
      GLOBAL_VARIABLE?.genesysIntent != ""
    ) {
      setChatInitiate(false);
      const chatEnable = localStorage.getItem("chatEnable");
      if (chatEnable === "true") {
        callGenesys();
      }
    }

    Genesys("subscribe", "Conversations.closed", function () {
      localStorage.setItem("chatEnable", "false");
    });
  });

  return (
    <>
      {liveChatField && !isTermedMember && isMemInfoResponseLoaded ? (
        getFieldValue(liveChatField[0]?.fields, "enableChat", false) ? (
          <div>
            <div
              className="showChat chat-btn custom-tooltip"
              onClick={() => {
                toggleMessenger();
                callGenesys();
              }}
            >
              <img
                alt={props.fields.heading.value}
                className="img-molina-live-chat"
                src={`${ICON_PATH}/${
                  isCABrightcareMember ? "CHP_live-chat.svg" : "live-chat.svg"
                }`}
              />
              {/* <Text field={props.fields.heading}></Text> */}
              <span className="custom-tooltip-text-livechat">
                {getFieldValue(
                  liveChatField[0]?.fields,
                  "liveChatInfo",
                  false
                ) ?? {}}
              </span>
            </div>
          </div>
        ) : (
          ""
        )
      ) : (
        ""
      )}
    </>
  );
};
export default withModalProvider(LiveChat);
