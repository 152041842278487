import React, { useState, useEffect, useContext } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import MedicationsDetails from "./MedicationsDetails";
import { useMedicationsAPI } from "../../hooks/useMedicationsAPI";
import { Formik, Form } from "formik";
import {
  MARKETPLACE,
  GLOBAL_VARIABLE,
  NM,
  MEDICAID,
  NM_MEDICAID_ProgramID,
  WA_MD_APPLE,
  WA,
  CHP_DATE,
} from "../../define.constants";
import { AppContext } from "../../../src/contexts/app.context";
import MedicationsRecords from "./MedicationsRecords";
import { printReportDate, formSchema } from "../Common-Health-Records/Helper";
import { getFormattedDate, getStateConfiguration } from "../../utils/helpers";
import ServiceDescription from "../MyHealthRecord-Layout/Common/ServiceDescription";
import DisplaySearch from "../MyHealthRecord-Layout/Common/DisplaySearch";
import { SpinnerContext } from "../../contexts/spinner.context";
import { MY_HEALTH_RECORD_VIEW_MEDICATIONS } from "../../define.widgets";
import { FooterDescription } from "../MyHealthRecord-Layout/Common/FooterDescription";

const MyHealthRecordMedications = (props) => {
  /* Search useState Variables */
  const [searchTerm, setSearchTerm] = useState(null);
  const [userAction, setUserAction] = useState(null);
  const { innerSpinnerHTML } = useContext(SpinnerContext);

  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props.fields, "intent");

  /**Filtering useState Variables */
  const [filteredRecords, setFilteredRecords] = useState([]);

  const { fields } = props;
  const [medicationDetails, setMedicationDetails] = useState(true);

  const {
    medicationsList,
    medicationsMessage,
    medicationsDisclaimer,
    ismedloaded,
  } = useMedicationsAPI(searchTerm);

  useEffect(() => {
    setFilteredRecords(medicationsList);
  }, [medicationsList]);

  /** Formik implementation */
  const initialValues = {
    startDate: null,
    endDate: null,
  };

  const resetPage = () => {
    setUserAction(null);
  };

  //Handle OnSubmit for Search
  const onSubmitHandler = (values) => {
    resetPage();
    setUserAction("Search");
    let filterParams = {
      startDate: values.startDate && getFormattedDate(values.startDate),
      endDate: values.endDate && getFormattedDate(values.endDate),
    };
    setSearchTerm(filterParams);
  };

  /* Medication Details Props */
  const [medicationName, setMedicationName] = useState("");
  const [prescribingDoctor, setPrescribingDoctor] = useState("");
  const [prescriberNPI, setPrescriberNPI] = useState("");
  const [dateFilled, setDateFilled] = useState("");
  const [dateWritten, setDateWritten] = useState("");
  const [quantity, setQuantity] = useState("");
  const [daysSupply, setDaysSupply] = useState("");
  const [refillsAuthorized, setRefillsAuthorized] = useState("");
  const [refillsLeft, setRefillsLeft] = useState("");
  const [pharmacyNPI, setPharmacyNPI] = useState("");

  const NavigateToMedicHistory = (e, ...props) => {
    e.preventDefault();
    setMedicationDetails(false);
    setMedicationName(props[0]);
    setPrescribingDoctor(props[1]);
    setPrescriberNPI(props[2]);
    setDateFilled(props[3]);
    setDateWritten(props[4]);
    setQuantity(props[5]);
    setDaysSupply(props[6]);
    setRefillsAuthorized(props[7]);
    setRefillsLeft(props[8]);
    setPharmacyNPI(props[9]);
  };

  /** Back To List  */
  const BacktoMedDetals = (e) => {
    e.preventDefault();
    setMedicationDetails(true);
    setMedicationName("");
  };

  const {
    memberLOB,
    IsWISSIMember,
    programId,
    MemberStateName,
    isCABrightcareMember,
  } = useContext(AppContext);
  const isMarketplaceMember =
    memberLOB.toLowerCase() === MARKETPLACE.toLowerCase();
  const isMedicaid = memberLOB.toLowerCase() === MEDICAID.toLowerCase();
  const getDisclaimerMessage = (fields) => {
    let disclaimer = null;
    if (IsWISSIMember && IsWISSIMember === true) {
      disclaimer = fields.lblDisclaimerMediWI.value;
    } else if (isMarketplaceMember) {
      disclaimer = fields.lblDesc1.value;
    } else if (
      isMedicaid &&
      programId == NM_MEDICAID_ProgramID &&
      MemberStateName.toUpperCase() == NM.toUpperCase()
    ) {
      disclaimer = fields.lblDescriptionNMNonMP.value;
    } else if (
      isMedicaid &&
      programId == WA_MD_APPLE &&
      MemberStateName.toUpperCase() == WA.toUpperCase()
    ) {
      disclaimer = fields.lblDescriptionWANonMp.value;
    } else {
      disclaimer = fields.lblDisclaimerMediNonMP.value;
    }
    return disclaimer;
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={formSchema(
          props.fields.lblDateError.value,
          props.fields.lblMinMaxDateError.value
        )}
        onSubmit={onSubmitHandler}
      >
        {(formikProps) => (
          <Form>
            <div
              className="tab-pane fade show active referrals-tab"
              id="medications"
              role="tabpanel"
              aria-label="tab-1"
            >
              {medicationDetails == true ? (
                <div className="row no-tab-title" id="medicationDate">
                  {ismedloaded ? (
                    <div className="col-12">
                      {userAction === null &&
                      (filteredRecords === null ||
                        filteredRecords?.length === 0) ? (
                        <>
                          <div className="no-records">
                            <Text
                              field={{ value: FooterDescription(props.fields) }}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="row">
                              <div className="col-md-12 page-subheading mb-4">
                                <Text field={props.fields.lblDateFilled} />
                              </div>
                            </div>
                            <DisplaySearch
                              props={props}
                              startDateDisclaimer={medicationsMessage}
                              formikProps={formikProps}
                              showProvider={false}
                              minDate={isCABrightcareMember ? CHP_DATE : ""}
                            />

                            <hr />
                            {filteredRecords?.length < 1 ? (
                              <p className="no-records">
                                <Text field={props.fields.lblNoResults} />
                              </p>
                            ) : (
                              <>
                                <div className="col-md-12 print-heading mb-4 d-none">
                                  <Text field={props.fields.lblprintHeading} />
                                </div>
                                <MedicationsRecords
                                  fields={props.fields}
                                  data={filteredRecords}
                                  NavigateToMedicHistory={
                                    NavigateToMedicHistory
                                  }
                                />
                              </>
                            )}
                            {printReportDate(props.fields.lblPrintGenerated)}
                          </div>
                        </>
                      )}
                      <ServiceDescription
                        disclaimer={getDisclaimerMessage(props.fields)}
                        contactUs={props.fields.lnkContactUs}
                        isCABrightcareMember={isCABrightcareMember}
                      />
                    </div>
                  ) : (
                    filteredRecords?.slice(0).length === 0 && (
                      <li className="list-group-item">
                        {innerSpinnerHTML?.[MY_HEALTH_RECORD_VIEW_MEDICATIONS]}
                        <div className="row field1">
                          <div className="col-sm-12"></div>
                        </div>
                      </li>
                    )
                  )}
                </div>
              ) : (
                <div>
                  <MedicationsDetails
                    fields={fields}
                    BacktoMedDetals={BacktoMedDetals}
                    medicationName={medicationName}
                    prescribingDoctor={prescribingDoctor}
                    prescriberNPI={prescriberNPI}
                    dateFilled={dateFilled}
                    dateWritten={dateWritten}
                    quantity={quantity}
                    daysSupply={daysSupply}
                    refillsAuthorized={refillsAuthorized}
                    refillsLeft={refillsLeft}
                    pharmacyNPI={pharmacyNPI}
                  />
                  <ServiceDescription
                    disclaimer={getDisclaimerMessage(props.fields)}
                    contactUs={props.fields.lnkContactUs}
                    isCABrightcareMember={isCABrightcareMember}
                  />
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default MyHealthRecordMedications;
